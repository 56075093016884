

(function () {
    //==================================================
    //sets namespaces for module
    //==================================================
    var ns = window.b$l;
    var $$ = ns.$$;
    var fconf = ns.fconf;
    var fapp = ns.sn('fapp');
    var fmethods = ns.sn('methods', fapp);
    var aModel = ns.sn('aModel', fapp);
    var fheap = ns.sn('fheap', fapp);
    var ccc = console.log; ccc && (ccc = console.log);

    fmethods.mdcss__dashb = mdcss__dashb;
    fmethods.dcss__dashb_someFrames = dcss__dashb_someFrames;
    fmethods.mcss__dashb_frames_detectsHeights = mcss__dashb_frames_detectsHeights;
    return;








    ///==================================================
    /// function's union;
    /// control panel styles;
    ///==================================================
    function mdcss__dashb() {
        fmethods.mcss__dashb_detects_condensityScale();
        fmethods.mcss__dashb_frames_detectsHeights();
        fmethods.dcss__dashb_someFrames();
        //fmethods.cont_rightCP(); //todo? no need?
        fmethods.inldynCSS_CP();
    }
    function mcss__dashb_frames_detectsHeights() {
        var superbox = $$.q('.plugin-superroot').box();
        var winheight = window.innerHeight;

        //------------------------------------------
        // //\\ establishes heights
        //------------------------------------------
        /*
        var dt = currtpl.docbody;
        var BOTTOM_MARGIN_PATCH = 10;
        var visiblePlayGroundHeight =
                fheap.editedPageScale *
                dt.pages.population.length * (
                    dt.pstyle.PAGE_HEIGHT +
                    fconf.DASHBOARD.PAGE_MARGIN_TOP_PX //for visbile vertical margins
                );
        fheap.pluginHeight = ( BOTTOM_MARGIN_PATCH +
                             fconf.CONTROL_PANELS.APP_CP_HEIGHT +
                             visiblePlayGroundHeight ).toFixed()+'px';
        fheap.pagesSuperHeight = ( visiblePlayGroundHeight ).toFixed()+'px';
        */

        //------------------------------------------------------
        // //\\ restricts plugin to visible window height
        //------------------------------------------------------
        //.     1. resonalble anyway
        //.     2. helps to keep right side bar fixed and available to user
        //.        when doc pages are scrolled
        var CP = fconf.CONTROL_PANELS;
        fheap.pluginHeight = winheight;
        fheap.pagesSuperHeight = (winheight - CP.APP_CP_HEIGHT - CP.APP_CP_BOTTOM_GAP);
        if (!fconf.DEVELOPER_MODE) {
            if (superbox) {
                fheap.pluginHeight -= superbox.top;
                fheap.pagesSuperHeight -= superbox.top;
            }

        }
        fheap.pluginHeight = fheap.pluginHeight.toFixed() + 'px';
        fheap.pagesSuperHeight = fheap.pagesSuperHeight.toFixed() + 'px';
        //ccc( 'winheight=' + winheight + ' superbox.top' + superbox.top )
        //------------------------------------------------------
        // \\// restricts plugin to visible window height
        // \\// establishes heights
        //------------------------------------------
    }


    function dcss__dashb_someFrames() {
        aModel.pluginRoot$
            .css('min-height', fheap.pluginHeight) //todm
            ;
        aModel.pagesSuperparent$
            .css('height', fheap.pagesSuperHeight)
            ;
        $$
            .q('.plugin-root .dashboard-left-side-bar')
            .css('height', fheap.pagesSuperHeight);
        ;
        $$
            .q('.plugin-root .dashboard-right-side-bar')
            .css('height', fheap.pagesSuperHeight);
        ;
    }



})();
