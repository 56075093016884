import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import $ from 'jquery'
import Alert from './alert';
import Search from './patient-search'
import Paginator from './paginator'


class PatientsSelect extends Component {

	constructor(props) {
		super(props)
		this.url = '/patients'
		this.state = { 
			patients: [],
			searched: [],
			limit: 10,
			offset: 0,
			limitedPatients: [],
			fetchingPatients: false,
			alertMessage: null,
			alertType: 'alert-success'
		}

		this.setFilteredPatients = this.setFilteredPatients.bind(this)
		this.dataHandler = this.dataHandler.bind(this)
		this.handelPatientSelection = this.handelPatientSelection.bind(this)
		this.renderListItem = this.renderListItem.bind(this)
	}

	componentDidMount() {

		this.read()
	}

	componentDidUpdate(props, state) {
		var userUpdated = this.props.user.id !== props.user.id
		var organizationUpdated = this.props.organization.id !== props.organization.id
		if (userUpdated || organizationUpdated) { 
			this.setState({
				patients: []
			})
			this.read() 
		}
	}

	searchHandler(event) {
		event.preventDefault();
		const searchTermFirst = event.target.elements.firstName.value.toLowerCase();
		const searchTermLast = event.target.elements.lastName.value.toLowerCase();
		const filtered = this.state.patients.filter(
		  e =>
			e.firstName.toLowerCase().includes(searchTermFirst) &&
			e.lastName.toLowerCase().includes(searchTermLast)
		);
	
		const filteredOut = Array.from(new Set(filtered.map(e => e.id))).map(id =>
		  filtered.find(e => e.id === id)
		);
	
		if (filteredOut.length < 1) {
		  this.setState(
			prevState => ({
			  ...prevState,
			  searched: [],
			  searchMessage: "No Results Found"
			}), () => {
				// clear the message after 3 seconds
				setTimeout(() => {
				  this.setState({ searchMessage: "" });
				}, 3000);
			}
		  );
		} else {
		  this.setState(prevState => ({
			...prevState,
			searched: filteredOut,
			searchMessage: ""
		  }));
		}
	}

	read(params) {
		var success = this.dataHandler.bind(this)
		var options = {
			organizationId: this.props.organization.id,
			limit: 100,
			offset:this.state.offset
		}
		for (var key in params) {
			var value = params[key]
			if (value) options[key] = params[key]
		}

		$.ajax({
			method: 'GET',
			url: this.url,
			data: options,
		}).done(success)
	}

	dataHandler(data) {
		let newPatients = this.state.patients.slice()
		data.rows.forEach(newPatient => {
			newPatients.push(newPatient)
		});
		let hasMore = data.count > newPatients.length ? true : false

		this.setState({
			patients: newPatients,
			offset: this.state.offset + 100,
			fetchingPatients: hasMore
		}, () => {
			if (hasMore) {
				this.read()
			} else {
				this.setState({
					offset: 0
				})
			}
		})
	}
	removeAlert() {
		this.setState({
		  alertMessage: null,
		});
	  }

	setFilteredPatients(data){
		this.setState({
			limitedPatients: data
		})
	}

	clearOffSet(){
		this.setState({
			offset: 0
		})
	}
	

	handelPatientSelection(secondPatientData){
		let mainPatientData = this.props.mainPatientData
		const userConfirmed = window.confirm(`Are you Sure you want to combine these patients which would result in the removal of: ${secondPatientData.firstName}, ${secondPatientData.lastName}`)

		
		let failure = (res) => {
			let body = res.responseJSON;
			if(res.status !== 200){
				this.setState({
					alertMessage: res.statusText,
					alertType: 'alert-danger'
				});
			} else {
				let error = new Error(body.error.message);
				this.setState({
					alertMessage: error.message,
					alertType: 'alert-danger'
				});
			}
		}
		
		if (userConfirmed) {
			let data = {
				"mainPatientId": this.props.mainPatientData.id,
				"secondaryPatientId": secondPatientData.id
			};
	
			$.ajax({
				method: 'PUT',
				url: `/patients/${this.props.mainPatientData.id}/merge`,
				data: data,
			}).done((response) => {
				// Call the closeModal prop function on successful AJAX request
				if (this.props.closeModal) {
					this.props.closeModal();
				}
			}).fail(failure); // Make sure the failure callback is defined or implemented
		} else {
			console.log("User Cancelled");
		}


	}


	renderListItem(data) {
		var id = data.id
		var name = data.firstName + ' ' + data.lastName
		var birthDate = moment(data.birthDate).utc().format('MMMM D, YYYY')
		return (
			<li key={id} className='list-group-item list-group-item-action' onClick={() => this.handelPatientSelection(data)}>
					<div className='ml-4 row'>
						<span>{name}</span>
					</div>
					<div className='ml-4 row'>
						<small>{birthDate}</small>
					</div>

			</li>

		)
	}

	// submit(event) {
	// 	if (event) event.preventDefault()
	// }



	render() {
		var patients = this.state.limitedPatients.map(this.renderListItem)
		var conditionalData
		if (this.state.searched.length > 0) {
			conditionalData = this.state.searched
		}else{
			conditionalData = this.state.patients
		}

		return (
			<div className='card'>
				<div className='card-header'>
					<div className='row align-items-center'>
						<div className='col'>
							<span className='h4'>Select Patient to Merge with</span>
						</div>
					</div>
				</div>
				<div className='card-body'>
					<div>
						<div className='row'>
							<div className='col'>
								<Search onSubmit={this.searchHandler.bind(this)} />
							</div>
						</div>
						<div className='row'>
							<div className='col'>
								<ul className='list-group'>
									{patients}
								</ul>
							</div>
						</div>
					</div>
					<div className='col mt-2'>
						<nav>
							<Paginator data={conditionalData}
								limit={this.state.limit}
								render={this.setFilteredPatients} />
						</nav>
					</div>
				</div>
				<Alert
				message={this.state.alertMessage}
				type={this.state.alertType}
				onExit={this.removeAlert}
				/>
			</div>
		)
	}

}

export default PatientsSelect

//TODO: close the modal when it works