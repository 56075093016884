//==================================================
//imports
//==================================================
import React from "react";
import $ from 'jquery'
var ccc = console.log; ccc && ( ccc = console.log );


//==================================================
//sets namespaces for module
//==================================================
var ns	        = window.b$l;
var $$          = ns.$$;
var fconf       = ns.fconf;
var fapp        = ns.sn('fapp' );
var fheap       = ns.sn('fheap', fapp);
var fmethods    = ns.sn('methods',fapp);
var currtpl     = ns.sn('currtpl', fconf);
var tplpopul    = ns.sn('tplpopulation', fconf);

var aModel      = ns.sn('aModel', fapp);
var pgModels    = ns.sn('pgModels', aModel, [] );

var handleDocListChange = null;
//var DONT_SAVE_TO_SERVER_OTHER_DOCS = false; //for dev only


fmethods.saveTemplate = saveTemplate;
fmethods.setAjaxCSSState = setAjaxCSSState;











function doc__model8internals8dashBars_no_leftBar()
{
    fmethods.doc__dataModel();
    fmethods.dash8doc__internals(); //todo ... right side bar is redundant here
    fmethods.forceUpdateTopBarCP();
    fmethods.updateRightSideBar();
}


function deleteDoc({
    docid,
    dontSelectOtherAfter, //optional
}) {
    if( Object.keys( tplpopul ).length < 2 ) {
        alert( 'Before deleting single document, the other new must be added.' );
        return;
    }
    fmethods.finalizes_tasksInProgress();

    if( !window.DEVELOPER_MODE__IS__ON ) {
        var ajaxargs = {
            method : 'DELETE',
            url : '/report-template/' + docid,
            data : '',
            contentType: "application/json",
            dataType : 'json',
        };
        fmethods.setAjaxCSSState( !!'add' );
        $.ajax( ajaxargs )
        .done(
            ( respObj ) => {
                ////does the job for successfull response
                //ccc( 'ajax success. ', respObj  );
                if( respObj.id+'' === '' + docid && respObj.deleted === true ) {
                    var capt = currtpl.doccaption;
                    deletes_and_establishes_other_doc({ docid, dontSelectOtherAfter });
                    ccc( 'Deleted "' + capt + '" db-id="' + docid + '"' );
                } else {
                    ccc( respObj );
                    alert( 'Server cannot delete "' + currtpl.doccaption + '" db-id="' + docid + '"' );
                }
                fmethods.setAjaxCSSState( !'add' )
            }
        ).fail( 
            ( api ) => {
                ccc(  "ajax error", api.responseText );
                fmethods.setAjaxCSSState( !'add' );
            }
        );
    } else {
        deletes_and_establishes_other_doc({ docid, dontSelectOtherAfter });
    }
    return;

    function deletes_and_establishes_other_doc({ docid, dontSelectOtherAfter })
    {
        delete tplpopul[ docid ];
        if( dontSelectOtherAfter ) return;

        //=============================================
        // //\\ cleans up and populates currtpl
        //=============================================
        //last doc is not deleted ... so this is safe:
        docid = Object.keys( tplpopul )[0];
        //cleans up garbage inside curtpl
        Object.keys( currtpl ).forEach( dkey => {
            delete currtpl[ dkey ];
        });
        Object.assign( currtpl, tplpopul[ docid ] );
        currtpl.docid = docid;
        pgModels.length = 0;
        //=============================================
        // \\// cleans up and populates currtpl
        //=============================================

        //=============================================
        // //\\ does heavy-lifting
        //=============================================
        doc__model8internals8dashBars_no_leftBar();
        //=============================================
        // \\// does heavy-lifting
        //=============================================

        //=============================================
        // //\\ populates selector
        //=============================================
        //todo ... note "chosen" docid may become undefined for a while ...
        //         until somewhere in the code it is reestablished ...
        aModel.docsSelector$.html( '' );
        Object.keys( tplpopul ).forEach( (dkey,dix) => {
            setDocSelectionOption({ dkey, selectedDocId : docid })
        })
        //apparently an alternative: 
        //aModel.docsSelector$().value = docid;
        fmethods.update_unsavedFlag_in_CSS();
        //=============================================
        // \\// populates selector
        //=============================================
    }
}




//==================================================
//
//==================================================
class DocSelectors_ControlPanel extends React.Component {

    constructor( props ) {
        super( props )
        handleDocListChange = this.handleDocListChange.bind(this);
    }

    componentDidMount()
    {
        this.establishDocsSelector();
    }

    componentDidUpdate()
    {
        this.establishDocsSelector();
    }

    establishDocsSelector() {
        var parent = document.querySelector( '.select-doc-template-parent' )
        parent.innerHTML = '';
        if( !aModel.hasOwnProperty( 'docsSelector$' ) ) {
            this.createDocsSelector();
        }
        parent.appendChild( aModel.docsSelector$() );
    }


    ///========================================
    /// creates documents' list selector
    /// with options;
    /// not as React-component
    ///========================================
    createDocsSelector() {
        //-----------------------------------------------
        // //\\ creates select dom placeholder
        //-----------------------------------------------
        var that = this;
        aModel.docsSelector$ =
            $$.c( 'select' )
            .addClass( 'cp-button select-doc-template' )
            .a( 'title', "Select Document Template" )
            .e( 'click', that.handleDocListChange.bind(this) )
            //todm: .e( 'click', handleDocListChange )
            ;
        //-----------------------------------------------
        // \\// creates select dom placeholder
        //-----------------------------------------------

        //-----------------------------------------------
        // //\\ establishes chosen docid
        //-----------------------------------------------
        var docid = '';
        var tkeys = Object.keys( tplpopul );
        if( tkeys.length ) {
            ///detects chosen docid
            docid = tkeys[0];
            ns.eachprop( tplpopul, tpl => {
                docid = ( tpl.docbody.chosenInDash && tpl.docid ) || docid;
            });
            /*
            //no good:
            aModel.docsSelector$.a( 'value', '' );
            aModel.docsSelector$.a( 'value', docid );
            */
            //todm: this is more consistent ... but now,
            //app possibly builds internals "manually"
            //this.handleDocListChange({ target:{value:docid} } ) 
        }
        //-----------------------------------------------
        // \\// establishes chosen docid
        //-----------------------------------------------

        //-----------------------------------------------
        // //\\ builds select-element and its options
        //-----------------------------------------------
        tkeys.forEach( (dkey,dix) => {
            setDocSelectionOption({ dkey, selectedDocId : docid });
        });
        //aModel.docsSelector$().value = docid;
        fmethods.update_unsavedFlag_in_CSS();
        //-----------------------------------------------
        // \\// builds select-element and its options
        //-----------------------------------------------
    }

    //==========================
    // scroll list
    //==========================
    handleDocListChange( event ) {

        //cleanup ... todm make more elegant cleanup ... this is too complex ...
        fmethods.finalizes_tasksInProgress();

        //-------------------------------------------
        // //\\ establishing new dkey and dom-cleanup
        //-------------------------------------------
        if( event.target.value !== currtpl.docid ) {
            ////not a new doc created ...
            var foundDocId = null;
            Object.keys( tplpopul ).forEach( (dkey,dix) => {
                if( event.target.value === dkey ) {
                    foundDocId = dkey;
                }
            });
            if( foundDocId === null ) {
                ccc( 'no Document with ' + event.target.value + ' doc-key exists' ); 
                return;
            }
        } else {
            foundDocId = event.target.value;
        }

        pgModels.forEach( (pm,pix) => {
            pm.dom$$.editorPage$.html('');
        });
        pgModels.length = 0;

        //-------------------------------------------
        // //\\ establishes curtpl shortcut
        //-------------------------------------------
        //cleans up garbage inside curtpl

        //fmethods.chcurr( 'in change handler' );


        Object.keys( currtpl ).forEach( dkey => {
            delete currtpl[ dkey ];
        });

        //populates currtpl
        Object.assign( currtpl, tplpopul[ foundDocId ] );

        //-------------------------------------------
        // \\// establishes curtpl shortcut
        // \\// establishing new dkey and dom-cleanup
        //-------------------------------------------

        //-------------------------------------------
        // //\\ rebuilds doc and its internals
        //-------------------------------------------
        doc__model8internals8dashBars_no_leftBar();
        //-------------------------------------------
        // \\// rebuilds doc and its internals
        //-------------------------------------------

        ns.eachprop( tplpopul, tpl => tpl.docbody.chosenInDash = false ); //cleans up
        tplpopul[ foundDocId ].docbody.chosenInDash = true; //shows to user
        fmethods.update_unsavedFlag_in_CSS();
    }




    //==========================
    // add
    //==========================
    addDoc8updateServer( event ) {

        fmethods.finalizes_tasksInProgress();

        //=================================================
        // //\\ establishes new unique document id, newKey
        //=================================================
        ///React Wrong Baby Sitting
        ///withoug wrapping into object, React does 
        ///wrong babysitting of "potentially unsave variables for function in loop"
        //var newKey = 0;
        //var testKey = 1;
        var rbc = {
            newKey : 0,
            testKey : 1,
        };
        while( rbc.newKey === 0 ) {
            rbc.newKey = rbc.testKey;
            Object.keys( tplpopul ).forEach( (tkey) => {
                if( tkey === '' + rbc.testKey ) {
                    rbc.newKey = 0;
                }
            });
            rbc.testKey++;
        }
        //=================================================
        // \\// establishes new unique document id, newKey
        //=================================================

        //=============================================================
        // //\\ name is a key in db-communications, makes sure it's new
        //      ? is a "new name" = babySitter.test_doccap ?
        //=============================================================
        ///React Wrong Baby Sitting
        var babySitter = {
            new_index : 0,
            new_doccaption : "New Doc Name ",
        };
        do {
            babySitter.fails = false;
            babySitter.new_index++;
            babySitter.test_doccap = babySitter.new_doccaption + babySitter.new_index;
            Object.keys( tplpopul ).forEach( (tkey) => {
                if( babySitter.test_doccap.toLowerCase() === tplpopul[ tkey ].doccaption.toLowerCase() ) {
                    babySitter.fails = true;
                }
            });
        } while( babySitter.fails )
        //=============================================================
        // \\// name is a key in db-communications, makes sure it's new
        //=============================================================


        //=================================================
        // //\\ establishes tplpopul[ newKey ]
        //=================================================
        var newKey = ''+rbc.newKey;
        tplpopul[ newKey ] = ns.paste( {}, ns.fconf.default_empty_doc );


        tplpopul[ newKey ].docid = newKey; //tmp key ... will be replaced with db-supplied key
        tplpopul[ newKey ].doccaption = babySitter.test_doccap;
        tplpopul[ newKey ].newtemplate = true; //will tell db that it is "POST" request

        /*
        ccc( '*** ' + tplpopul[ newKey ].doccaption + ' newKey="' + newKey +
        '" fresh doc imgRepo=', tplpopul[ newKey ].docbody.repo.imgRepo.substring(0, 55),
        ' repo=', tplpopul[ newKey ].docbody.repo )
        */

        //pgModels.length = 0;

        //todo ... this is done clumsy: will be reverted at ajax failure
        //.sets all tpl's to non-chosen
        //ns.eachprop( tplpopul, tpl => tpl.docbody.chosen = false ); //cleans up
        tplpopul[ newKey ].docbody.chosenInDash = true; //shows to user
        //=================================================
        // \\// establishes tplpopul[ newKey ]
        //=================================================


        //todo ... must revert back if db/net problems
        fmethods.saveTemplate({
            tpl :   tplpopul[ newKey ],
            callbakWhenSuccess : completeDocCreation,
        })
        return;


        //=================================================
        // //\\ establishes GUI
        //=================================================
        function completeDocCreation( tpl )
        {
            setDocSelectionOption({ dkey : tpl.docid, selectedDocId : tpl.docid });
            handleDocListChange({ target:{value:tpl.docid} } );
        }
        //=================================================
        // \\// establishes GUI
        //=================================================
    }




    //==========================
    // delete
    //==========================
    deleteCurrentDocLocally() {
        deleteDoc({ docid : currtpl.docid });
    }


    ///**********************************************
    ///
    ///**********************************************
    render() {
        return (
            <div className="react-wrap-of-select-doc-template-parent">
                <div className="select-doc-template-parent doc-level-controls"
                    key={"select-doc"+ns.fheap.domid++}
                />
                {
                    fconf.ROLE === 'CONSTRUCTOR' ?
                    (
                        <div key="select-doc-2">
                            <div className="cp-button doc-level-controls"
                                key="delete-template"
                                title="Delete Current Document Template"
                                onClick={this.deleteCurrentDocLocally.bind(this)} >
                                Delete
                            </div>
                            <div className="cp-button doc-level-controls"  key="add-doc-template"
                                 title="Add Document Template"
                                 onClick={ this.addDoc8updateServer.bind(this) } >
                                    Add
                            </div>
                        </div>
                    ) : ''
                }
            </div>
        );  //return
    } //render()
}

export default DocSelectors_ControlPanel;




function saveTemplate({ tpl, callbakWhenSuccess, })
{
    tpl.docbody.repo.imgRepo = tpl.docbody.repo.imgRepo || '';
    if( window.DEVELOPER_MODE__IS__ON ) {
        var tobesent = 
        {
            name: 'absolutely-new-name',
            data: tplpopul,
        };

    } else {
        ////we do clone tree to remove images-repo from submission
        tobesent =
        {
            name: tpl.doccaption,
            data: JSON.stringify( ns.clonetree( tpl.docbody ) ),
        };
    }

    var stringified = JSON.stringify( tobesent, null, ' ' );
    ccc ( 'sending: ' + tpl.doccaption + ' tobesent length=' + stringified.length );
    var ajaxargs = {
        method : tpl.newtemplate ? 'POST' : 'PUT',
        url : tpl.newtemplate ?
                '/report-template' :
                '/report-template/' + tpl.docid,
        data : stringified,
        contentType: "application/json",
        dataType : 'json',
    };
    ccc( ajaxargs.method + 'ing doc-tpl "' +
            tpl.doccaption + '"' +
            ( ajaxargs.url === '/report-template' ? '' : ' db-id="' + tpl.docid + '"'  ) +
            ' total len='+stringified.length
            //' size of template=' + tobesent.template.length + 
            //' imgrepo=' + tpl.docbody.repo.imgRepo.length
    );

    /*
    ccc( 'beginning ajax: ' );
    ns.eachprop( tplpopul, (tpl,tkey) => {
            ccc( 'key-index=' + tkey +
                    '=docid=' + tpl.docid +
                    ' len=' + Object.keys( tpl ).length +
                    ' keys=' + Object.keys( tpl ) );
    });
    */


    if( window.DEVELOPER_MODE__IS__ON ) {
        ajaxargs.method = 'POST';
        ajaxargs.url = fconf.dburl +
                       "savedb.php" +
                       "?filename=" + fconf.dbver;
        ajaxargs.dataType = 'text';
        ajaxargs.crossDomain = true;
        delete ajaxargs.contentType;
    }
    //----------------------------------------------
    // //\\ ajax alternatives
    //----------------------------------------------
    setAjaxCSSState( !!'add' );
    $.ajax( ajaxargs )
    .done(
        ( respObj ) => {

            ////does the job for successfull response
            //c cc( 'ajax success. ', respObj  );
            var formerId = tpl.docid;

            /*
            ccc( 'ajax success: ' );
            ns.eachprop( tplpopul, (tpl,tkey) => {
                    ccc( 'key-index=' + tkey +
                            '=docid=' + tpl.docid +
                            ' formerId=' + formerId +
                            ' len=' + Object.keys( tpl ).length +
                            ' keys=' + Object.keys( tpl ) );
            });
            */

            if( currtpl.docid === tpl.docid ) {
                ////todm ... remnant from saving different than curr tpl
                var doUpdateCurrtpl = true;
            }
            //.for user be able to edit new doc and send twice
            tpl.newtemplate = false;
            tpl.docid = respObj.id+'';
            tplpopul[ tpl.docid ] = tpl;
            if( doUpdateCurrtpl ) {
                currtpl.docid = tpl.docid; //todm ... the reason of this code excess is
                                           //that currtpl is not a reference to tpl ... poor design
            }
            if( tpl.docid !== formerId ) {
                ////deletes wrong id if server renamed it
                delete tplpopul[ formerId ];
            }
            ccc( 'Doc "' + tpl.doccaption + '" with db-id="' + tpl.docid + '" ' +
                 'apparently saved to db '
            );

            callbakWhenSuccess && callbakWhenSuccess( tpl, formerId );
            //todm .this still needs proofcheck that ajax response does not have
            //.error or some negative flag of upload failure
            fmethods.set_userWork_unsavedFlag_in_CSS( !'changed', tpl );
            setAjaxCSSState( !'add' );
        }
    ).fail( 
        ( api ) => {
            ccc(  "ajax error", api.responseText );
            //this has to be reverted at ajax failure

            //we should keep the doc chosen as is ... even at error
            //ns.eachprop( tplpopul, tpl => tpl.docbody.chosenInDash = false ); //cleans up
            //tplpopul[ Object.keys( tplpopul )[0] ].docbody.chosenInDash = true; //shows to user

            setAjaxCSSState( !'add' );
            alert( 'Saving document failed.' +
                   ' Did your network admin peers delete it while you were working?' );
        }
    )
    /*
    window.b$l.ajax.send({
        ajaxURL : ajaxargs.url,
        method : ajaxargs.method,
        adata : ajaxargs.data,
        onsuccess :
             ( respObj ) => {
                ccc( 'ajax success. ', respObj  )
        },
        onerror :
            ( api ) => {
                ccc(  "ajax error", api );
            },
    });
    */
    //----------------------------------------------
    // \\// ajax alternatives
    //----------------------------------------------

}


function setAjaxCSSState( add0subtract )
{
    fheap.ajaxInProgress += add0subtract ? 1 : -1;
    var proot$ = $$.q('.plugin-root');
    if( fheap.ajaxInProgress ) {
        proot$.addClass( 'ajax-in-progress' );
    } else {
        proot$.removeClass( 'ajax-in-progress' );
    }
}


function setDocSelectionOption({ dkey, selectedDocId }) {

    var wwt = tplpopul[ dkey ];
    var option$ = $$.c( 'option' )
      .a( 'name', dkey )
      .a( 'value', dkey )
      .a( 'title',
            'Doc Title = "'             + wwt.doccaption +
            '" Database Record ID="'    + dkey +
            '" Organization Id="'       + wwt.organizationId +
            '" Created At="'            + wwt.createdAt +
            '" Updated At="'            + wwt.updatedAt +
       '"' )

       //todm relies on that dkey === db-id is a number
      .addClass( 'doc-selection-option-'+dkey )

      .html( tplpopul[ dkey ].doccaption )
      .to( aModel.docsSelector$ )
      ;
    if( selectedDocId === dkey ) {
        option$.a( 'selected' );
        //apparently an alternative: aModel.docsSelector$().value = dkey;
    }
    if( tplpopul[ dkey ].unsavedToDb ) {
        option$.addClass( 'doc-unsaved-to-db' );
    }
}



