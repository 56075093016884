import React, {Component} from 'react'

// https://www.highcharts.com/docs/
import Highcharts from 'highcharts'

//https://github.com/bokuweb/react-rnd#install
// import { Rnd } from 'react-rnd'

// documentation on highcharts-react-official https://www.npmjs.com/package/highcharts-react-official
import HighchartsReact from 'highcharts-react-official'


import HC_more from 'highcharts/highcharts-more' //module
import HC_exporting from 'highcharts/modules/exporting'
import HC_exporting2 from 'highcharts/modules/offline-exporting'

HC_more(Highcharts) //init module
HC_exporting(Highcharts);
HC_exporting2(Highcharts);



class Charts extends Component {

    constructor(props){
        super(props)
        this.state = {
            chart: null,
            inactiveReports: [],
            /*
            rndStyle: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#f0f0f0"
            },
            */
            //hidden: false,
            resizing: false,
            //width: 400,
            //height: 400,


            x: this.props.x,
            y: this.props.y,
            chartOptions: {


                title: {
                    text: this.props.name
                },
                chart: {
                    borderWidth: 0,
                    //*******************************
                    //enforce basic reflow
                    //*******************************
                    //width: null,  //400,
                    //height: null, //400,
                    //reflow: true, //false,
                    //*******************************
                    //*******************************
                    //for manual reflow
                    //*******************************
                    animation : false,
                    width: null,
                    height: null,
                    reflow: false,
                    //*******************************
                },
                yAxis: {
                },
                xAxis: {
                    categories: []
                },
                series: [{
                    name: this.props.name,
                    data: []
                }],
                
            }
        }
        
    }


    componentDidMount(){
        var reports = []
        // loops through each report and pushes its data to reports[] if the data key matches the given name
        for (var key in this.props.reports){
            for (var name in this.props.reports[key].data){
                if (name !== undefined && name === this.props.name){
                    var dataPoint = this.props.reports[key].data[name]
                    reports.push(parseInt(dataPoint))
                }
            }
        }
        this.setState({
            chartOptions: {
                series: [{
                    data: reports
                }]
            }
        })
        /*
        console.log( 'adding to hard fun', this.state.chartOptions );
        window.b$l.methods.addChartForPDFExport({
            options : this.state.chartOptions
        });
        */
    }

    // Gets a reference for the created chart (May need refactored)
    createReference(chartRef){

        if (chartRef !== this.state.chart){
            this.setState({
                chart: chartRef
            }, () => {this.handleChartReference()})
            
        }
    }

    // Sends this compenent to compare-reports.js to be stored in its state
    handleChartReference(){
        this.props.addChartToProps(this)
    }

    // Not currently in use (May need refactored)
    populateChart(){
        var reports = []
        for (var key in this.props.reports){
            for (var name in this.props.reports[key].data){
                if (name !== undefined && name === this.props.name){
                    var dataPoint = this.props.reports[key].data[name]
                    reports.push(parseInt(dataPoint))
                }
            }
        }
    }

    // Sets whether a chart should be hidden or shown
    toggleChart(){
        this.setState({
            hidden: !this.state.hidden
        })
    }

    /*
    // Uses react-rnd to resize charts
    resizeChart(e,direction,ref,delta, position){
        var newHeight = ref.style.height
        var newWidth = ref.style.width
        this.setState({
            chartOptions: {
                chart: {
                    width: parseInt(newWidth),
                    height: parseInt(newHeight)
                }
            },
            height: newHeight,
            width: newWidth,
            resizing: false,
            ...position
        })
    }
    */

    
	render(){
        // options stores the data that the chart will display. This should get it's values from state/props
        // <div style={{width: this.state.width, height: this.state.height}}>
        var {chartOptions} = this.state

		return(
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
                callback={ this.createReference.bind(this)}
            />
		)
	}
}

export default Charts
