
export default function ( HRM, pstyle ) {

    return  {
        xAxis: {
            categories: [],
        },
        series:
        [
            {
                type: 'line',
                name: 'Total RDI',
                marker:{
                    radius: 6
                },
                lineWidth: 4,
                data: [], //total
                METAOPT_data_report_keys :
                [
                    //data: 'rdi'
                    'RDI4_OVERALL',
                ],
            },
            {
                type: 'line',
                lineWidth: 4,
                marker:{
                    radius: 6
                },
                name: 'RERA 2-4%',
                data: [], //rera
                METAOPT_data_report_keys :
                [
                    //data: 'rera'
                    'RDI4_RERA2TO4',
                ]
            },
            {
                type: 'line',
                lineWidth: 4,
                marker:{
                    radius: 6
                },
                name: 'Desat > 4%',
                data: [], //desat
                METAOPT_data_report_keys :
                [
                    //data: 'desat'
                    'RDI4_DESAT4',
                ]
            },
        ],
    }
}




