import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Alert from './alert'
import $ from 'jquery'

class ProxyLogin extends Component {

	constructor(props){
		super(props)
		this.state = {
			errorMessage: null
		}
	}

	submit(event){
		event.preventDefault()
		var elements = event.target.elements
		var failure = this.errorHandler.bind(this)
		$.ajax({
			method: 'POST',
			url: '/proxy/login',
			data: {
				username: elements.email.value,
				password: elements.password.value,
				proxy: elements.proxy.value
			}
		}).done(this.props.handleUser).fail(failure)
	}

	errorHandler(res){
		var body = res.responseJSON
		var error = body.error
		this.setState({
			errorMessage: error.message
		})
	}

	removeAlertHandler(){
		this.setState({
			errorMessage: null
		})
	}

	render(){
		var removeHandler = this.removeAlertHandler.bind(this)
		var submit = this.submit.bind(this)
		return (
			<div id='login'>
				<div className='col text-right'>
					<Alert message={this.state.errorMessage} type='alert-danger' onExit={removeHandler}/>
				</div>
				<h1 className='display-4 mt-4 mb-4'>Login</h1>
				<form onSubmit={submit}>
					<div className='form-group'>
						<label>Email address</label>
						<input type='text' className='form-control' id='email' placeholder='Enter email' />
					</div>
					<div className='form-group'>
						<label>Proxy</label>
						<input type='text' className='form-control' id='proxy' placeholder='Proxy email' />
					</div>
					<div className='form-group'>
						<label>Password</label>
						<input type='password' className='form-control' id='password' placeholder='Password' />
					</div>
					<div className='form-group form-check'>
						<input type='checkbox' className='form-check-input' />
						<label className='form-check-label'>Remember Me</label>
					</div>
					<button type='submit' className='btn btn-primary mb-3 mt'>Submit</button>
				</form>
				<div>
					<div className='mb-2'>
						<Link to='/signup'>Don't have an account?</Link>
					</div>
					<div>
						<Link to='/forgot'>Don't remember your password?</Link>
					</div>
				</div>
					
			</div>
		)	
	}

}

export default ProxyLogin
