import React, { Component } from 'react'

class Logout extends Component {

	render(){
		return <div></div>
	}

}

export default Logout