
import React from "react";
import './run-RightSideBar-ControlPanel.js'
import RepocardSelection from './RightSideBar-repocardSelection.js'
var ccc = console.log; ccc && ( ccc = console.log );


//==================================================
//sets namespaces for module
//==================================================
var ns	        = window.b$l;
var $$          = ns.$$;
var fconf       = ns.fconf;
var fapp        = ns.fapp;
var fmethods    = ns.sn('methods', fapp);
var currtpl     = ns.sn('currtpl', fconf);















//==================================================
// builds dashboard controls
//==================================================
class RightSideBar_ControlPanel extends React.Component {

    constructor( props ) {
        super( props );
        var dt = currtpl.docbody;
        this.state = {
            dataGroupIx: dt.contrPanelChosen_tplDatGrIx,
            chosenCardIx: dt.repo.dataGroups[ dt.contrPanelChosen_tplDatGrIx ].chosenIx,
        };
        this.handleChange = this.handleChange.bind(this);
        fmethods.updateRightSideBar = ( () => {
            var crepo$ = $$.q( '.cards-repo' );
            if( !crepo$() ) return;
            this.setState({
                dataGroupIx : this.state.dataGroupIx !==
                                    currtpl.docbody.contrPanelChosen_tplDatGrIx ?
                                        currtpl.docbody.contrPanelChosen_tplDatGrIx :
                                        this.state.dataGroupIx,
            });
            this.forceUpdate();
        }); //.bind( this ); //no need to bind
    }

    ///toggles drop down pane
    toggleDD () {
        var el$ = $$.q( '.dashboard-right-side-bar' );
        //ccc(  'closed?=', el$.hascls( 'closed' )  );
        el$.togcls( 'dropdown-closed' );
    }

    //---------------------------------
    // //\\ selection boxes
    //---------------------------------
    //data box
    handleChange( event ) {
        var cardTpl = null;
        var dataGroupIx = null;
        var dt = currtpl.docbody;
        dt.repo.dataGroups.forEach( (cTpl,dix) => {
            if( event.target.value === ''+dix ) {
                cardTpl = cTpl;
                dataGroupIx = dix;
            }
        });
        if( !cardTpl ) return;
        dt.contrPanelChosen_tplDatGrIx = dataGroupIx;
        this.setState({
            dataGroupIx: dataGroupIx,
        });
    }
    //---------------------------------
    // \\// selection boxes
    //---------------------------------




    ///**********************************************
    /// renders control panel
    ///**********************************************
    render() {
        var dt = currtpl.docbody;
        var dgIx = dt.contrPanelChosen_tplDatGrIx;

        //as of ver 1867, this render happens even when item is dragged from the right-side pane
        //as of ver 2647, no render happens after this drag-and-drop
        return (
            <div className="dashboard-right-side-bar dropdown-closed" key="dashboard-right-side-bar"
                onScroll={ _ => {
                    fmethods.inldynCSS_CP(); //vital to synch for d8d
                } }
                >
                <div className="right-side-cp" key="right-side-cp">
                    <div className="right-side-cp-dropdown-toggler">
                        <div className="cp-button" onClick={this.toggleDD}>
                            <i className="fa fa-cog" />
                        </div>
                    </div>
                    <div className="right-side-cp-dropdown">
                        <div className="select-dataid-template-parent" key="select-dataid">
                            <select className="cp-button select-dataid-template"
                                value={ dgIx }
                                onChange={ this.handleChange }
                            >
                            {
                                dt.repo.dataGroups.map( (cardTpl,dix) => {

                                    return (
                                        <option key={dix}
                                            name={dix} value={dix}
                                        >{cardTpl.dashCaption}
                                        </option>
                                    );
                                })
                            }
                            </select>
                        </div>
                    </div>
                </div>
                <RepocardSelection />
            </div>
        );  //return
    } //render() {
}

export default RightSideBar_ControlPanel;



