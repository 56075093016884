
var ns          = window.b$l;
var fconf       = ns.fconf;


var style = document.createElement( 'style' );
document.head.appendChild( style );

style.innerHTML = `

    /*****************************************/
    /* //|| download spinner                 */
    /*****************************************/
    .download-doc-spinner-parent {
        position : relative;
        width : 100%;
        height : 100%;
        min-height : 300px;
        xxxxbackground-color : ${fconf.DASHBOARD.COLORS.BACKGROUND};
        background-color : white;
        opacity : 0.8;
        z-index : 1000000;
    }

    .plugin-root .download-doc-spinner-parent {
        display : none;
    }
    .plugin-root.ajax-in-progress .download-doc-spinner-parent {
        display : block;
    }

    .download-doc-spinner {
        position : absolute;
        left : 50%;
        top : 50%;
        transform : translate(-50%, -50%);
        color :  ${fconf.DASHBOARD.COLORS.FONT_DARK};
        font-size : 20px;
        text-align : center;
    }
    /*****************************************/
    /* ||// download spinner                 */
    /*****************************************/



    /*****************************************/
    /* //|| version mark                     */
    /*****************************************/
    .report-composer-version {
        position : absolute;
        margin-left : 10px;
        bottom : 5px;
        right : 10px;
        font-size : 12px;
        vertical-align : bottom;
        opacity : 0.3;
        cursor : pointer;
    }
    .report-composer-version:hover {
        opacity : 1;
    }
    /*****************************************/
    /* ||// version mark                     */
    /*****************************************/

`;

