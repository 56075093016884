import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery'

class OrganizationDropdown extends Component {

	constructor(props){
		super(props)
		this.state = {
			organization: props.organization,
			organizations: props.organizations
		}
	}

	componentDidMount(){
		this.getOrganizations()
	}

	componentDidUpdate(props,state){
		if (this.props.user.id !== props.user.id){
			this.getOrganizations()
		}
	}

	getOrganizations(){
		if (this.props.user.id){
			var success = this.props.handleOrganizations
			$.get('/organizations?limit=200').done(success)
		}
	}

	handleClick(event){
		var id = parseInt(event.target.id)
		var defaultOrganization = {
			id: 0,
			name: '',
			email: '',
			phone: '',
			fax: '',
			npi: ''
		}
		var organization = this.props.organizations.find(function(org){
			return org.id === id
		}) || defaultOrganization
		this.props.handleOrganization(organization)					
	}

	renderDropdownItem(organization){
		var handleClick = this.handleClick.bind(this)
		return (
			<button className='dropdown-item' 
				id={organization.id}
				key={organization.id}
				onClick={ handleClick }
			>{ organization.name }
			</button>
		)
	}

	render(a,b,c){
		if (!this.props.user.id){
			return <li></li>
		}
		var organization = this.props.organization
		var title = organization.name || 'Select Organization'
		var handleClick = this.handleClick.bind(this)
		var renderDropdownItem = this.renderDropdownItem.bind(this)
 		var dropdownItems = this.props.organizations.map(renderDropdownItem)
		return (
			<li className='nav-item dropdown'>
				<a className='nav-link dropdown-toggle'
					href='null' 
					role="button" 
					data-toggle="dropdown" 
					aria-haspopup="true" 
					aria-expanded="false">{ title }</a>
				<div className='dropdown-menu'>
					{ dropdownItems }
					
					{/* <Link className='dropdown-item'
						id='0'
		 				to='/organizations/0'
		 				onClick={ handleClick }
		 			>New Organization
	 				</Link> */}
				</div>
			</li>
		)
	}

}

export default OrganizationDropdown