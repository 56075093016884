import React, {Component} from 'react'
import $ from 'jquery'

class Invite extends Component {

	constructor(props){
		super(props)
		this.state = {
			email: '',
			members: []
		}
		this.submitHandler = this.submitHandler.bind(this)
		this.changeHandler = this.changeHandler.bind(this)
	}

	componentDidUpdate(prevProps,prevState){
		var orgChanged = prevProps.organization.id !== this.props.organization.id
		if (orgChanged){
			this.fetch()
		}
	}

	fetch(){
		var success = this.dataHandler.bind(this)
		var url = '/members?organizationId=' + this.props.organization.id 
		$.get(url).done(success)
	}

	dataHandler(data){
		this.setState({
			members: data.rows
		})
	}

	changeHandler(event){
		var name = event.target.name
		var value = event.target.value
		var update = {}
		update[name] = value
		this.setState(update)
	}

	submitHandler(event){
		event.preventDefault()
		var data = {
			organizationId: this.props.organization.id,
			email: this.state.email,
			firstName: this.state.firstName,
			lastName: this.state.lastName
		}
		if (!data.organizationId || !data.email) return
		var success = this.handleInvitationSuccess.bind(this)
		var error = this.handleInvitationError.bind(this)
		$.post({
			url: '/members',
			method: 'POST',
			data: data
		}).done(success).fail(error)
	}

	handleInvitationSuccess(data){
		this.setState({
			email: '',
			firstName: '',
			lastName: ''
		})
		this.props.successHandler(data)
	}

	handleInvitationError(res){
		var data = res.responseJSON
		alert(data.error.message)
	}

	render(){
		
		return (
			<div className='card mb-4'>
				<div className='card-header'>
					<span className='h4 mt-4 mb-4'>Invite</span>
				</div>
				<div className='card-body'>
					<form className='form-inline' onSubmit={this.submitHandler}>
						<div className='input-group col'>
							<input className='form-control'
								name='firstName' 
								placeholder='First Name'
								defaultValue={this.state.firstName} 
								onChange={this.changeHandler} 
								/>
							<input className='form-control'
								name='lastName' 
								placeholder='Last Name'
								defaultValue={this.state.lastName} 
								onChange={this.changeHandler} 
							/>
						</div>
						<div className='input-group col'>
							<input className='form-control'
								name='email' 
								placeholder='Email'
								defaultValue={this.state.email} 
								onChange={this.changeHandler} 
								/>
							<div className='input-group-append'>
								<button className='btn btn-outline-primary' 
									type='submit'
									>Invite User</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		)
	}
	
}

export default Invite
