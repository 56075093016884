( function () {
	var ns	= window.b$l;









    /*
    Bounced function.
    Not a "bounced" by time in anyway. If condition not met, will wait forever.

    By bounced-function it is meant the function which waits for condition to execute:
    for example: assume funciton plot_sin() is bounced by condition of event of dowloading number PI

    in following "program" with calls separated in-time with "---":

        my_fun1() {
            plot_sin( PI*5 ); //1
            ...
        }
        ---
        my_fun2() {
            plot_sin( PI*6 ); //2
            ...
        }
        ---
        my_fun3() {
            plot_sin( PI*7 ); //3
            ...
        }
        ---
        my_fun4() {
            plot_sin( PI*8 ); //4
            ...
        }
        ---
    Assume the PI arrives in background between fun2 and fun3 calls.
    Then //3 will be executed with argument PI*7 
    Because of condition becomes "true", all later calls 4, ... are executed as usual and
    calls 1,2 ignored.

    If condition happens, the bounced function is guaranteed to be executed.
    If condition happens after all calls, the last one call will be executed.
    */

    ///returns function which waits until condition() begins true and
    ///then executes initially supplied original-function fun
    ///original-function has one argument: arg_
    ns.conditionalBounce = function( fun, condition, WAIT_INTERVAL_MS )
    {
        WAIT_INTERVAL_MS = WAIT_INTERVAL_MS || 200;
        var arg;
        var runs = null;
        return function( arg_ ) {
            arg = arg_;
            if( runs !== null ) return;
            checker();
            function checker()
            {
                if( condition() ) {
                    fun( arg );
                    runs = null;
                } else {
                    runs = setTimeout( checker, WAIT_INTERVAL_MS );
                }
            }
        };
    }

    ns.bouncer = function( fun, WAIT_INTERVAL_MS )
    {
        WAIT_INTERVAL_MS = WAIT_INTERVAL_MS || 200;
        var arg;
        var runs = null;
        return function( arg_ ) {
            arg = arg_;
            if( runs !== null ) {
                clearTimeout( runs );
            }
            runs = setTimeout( runner, WAIT_INTERVAL_MS );
            function runner()
            {
                    fun( arg );
                    runs = null;
            }
        };
    }





    ///This is a throttler not bouncer.
    ///This function code is update 103-06-28. Only wait === 0 argument is tested so far.
    ///
    ///Useful for resize event
    ///Callback: return-function signature:
    ///             doCallNow has effect of immediate calling plus effect of cleaning up,
    ///             arg can be used for ordinary app. paramters
    ///Input:    wait optional, how much to wait till call the most recent function-curry,
    ///          if falsy, then never wait and never curry.
    ///          Ultimately fires if called at elapsed >= wait. If not called this way, then 
    ///          times out to wait, since last call.
    ns.throttle = function( fun, wait )
    {
        var timeout = null;
        var timeStart = null;
        var arg;
        return function( arg_, doCallNow, doCancel, do_bounce ) {
            arg = arg_; //updates arg at every call
            var time = Date.now();
            timeStart = timeStart === null ? time : timeStart;
            var elapsed = time - timeStart;

            if( do_bounce ) {
                if(timeout !== null) {
                    clearTimeout( timeout );
                }
                //console.log( Date.now() + ' elapsed increased: elapsed='+elapsed);

            } else {
                if( !wait || elapsed > wait || doCallNow ) {
                    //console.log( Date.now() + ' wait='+wait+ ' elapsed='+elapsed+
                    //' doCallNow='+doCallNow);
                    fun( arg );
                    if( timeout !== null ) clearTimeout( timeout );
                    timeout = null;
                    timeStart = null;
                    return;
                }
                if( doCancel && timeout !== null ) {
                    clearTimeout( timeout );
                    return;
                }
                //.this statement prevents program from extension of the term
                //.in contrary to bouncer which extends the term
                if( timeout !== null ) return;
            }

	        timeout = setTimeout( 
                function() {
		            fun( arg );
		            timeout = null;
                    timeStart = null;
	            },
                wait
            );
            timeStart = time;
        };
    };


    /*
    ns.throttle8bounce = function({ funThrottle, funBounce, waitThrottle, waitBounce })
    {
        var timeout = null;
        var timeStart = null;
        var arg;
        return function({ arg_, doCallNow, doCancel, do_retrottle }) {
            arg = arg_; //updates arg at every call
            var time = Date.now();
            timeStart = timeStart === null ? time : timeStart;
            var elapsed = time - timeStart;

            if( do_retrottle ) {
                elapsed += wait;
                if(timeout !== null) {
                    clearTimeout( timeout );
                }
                //console.log( Date.now() + ' elapsed increased: elapsed='+elapsed);

            } else {
                if( !wait || elapsed > wait || doCallNow ) {
                    //console.log( Date.now() + ' wait='+wait+ ' elapsed='+elapsed+
                    //' doCallNow='+doCallNow);
                    fun( arg );
                    if( timeout !== null ) clearTimeout( timeout );
                    timeout = null;
                    timeStart = null;
                    return;
                }
                if( doCancel && timeout !== null ) {
                    clearTimeout( timeout );
                    return;
                }
                //.this statement prevents program from extension of the term
                //.in contrary to bouncer which extends the term
                if( timeout !== null ) return;
            }

	        timeout = setTimeout( 
                function() {
		            fun( arg );
		            timeout = null;
                    timeStart = null;
	            },
                wait
            );
            timeStart = time;
        };
    };
    */


}) ();


