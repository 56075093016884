const getToken = async () => {
  const token = localStorage.getItem('access_token');
  if (token) return token;
  const url =
    'https://analysis.elevare.tech/analysis-engine-research-influxdb-mysql/Token';
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

  var urlencoded = new URLSearchParams();
  urlencoded.append('username', 'report-manager@patientsafetyinc.com');
  urlencoded.append('password', 'Passw0rd!');
  urlencoded.append('grant_type', 'password');

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow',
  };
  const res = await fetch(url, requestOptions);
  const t = await res.json();
  if (t.access_token) localStorage.setItem('access_token', t.access_token);
  return t.access_token;
};

export const GetDefinitionData = async (type) => {
  const url = `https://analysis.elevare.tech/analysis-engine-research-influxdb-mysql/SleepStudyApi/Get${type}Definition`;

  const token = await getToken();
  var myHeaders = new Headers();
  myHeaders.append('Authorization', 'Bearer ' + token);

  const res = await fetch(url, {
    method: 'GET',
    headers: myHeaders,
  });
  const t = await res.json();
  return t;
};

export const SaveDefinitionData = async (type, data) => {
  const url = `https://analysis.elevare.tech/analysis-engine-research-influxdb-mysql/SleepStudyApi/Set${type}Definition`;

  const token = await getToken();
  var myHeaders = new Headers();
  myHeaders.append('Authorization', 'Bearer ' + token);

  const res = await fetch(url, {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(data),
  });
};
