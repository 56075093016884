
( function() {
    var ns          = window.b$l;
    var sn          = ns.sn;    
    var fapp        = sn('fapp' ); 
    var fmethods    = sn('methods',fapp);
    var d8d_p       = sn('d8d-point',fmethods);
    var ccc         = console.log; ccc && ( ccc = console.log );
    fmethods.initDragModel_tableComposer = initDragModel_tableComposer;
    return;








    //==========================================
    // //\\ inits drag points
    //==========================================
    function initDragModel_tableComposer( dtable )
    {
        var achivedCellFractionArray = [];
        var d8dFramework = d8d_p.createFramework({
            //.is editor-page
            dragSurface : dtable.tableParent.parentNode.parentNode,
        });
        createSizeDragger( dtable );
        dtable.dom$.cellSizes$.forEach( ( chandle$, cix ) => {
            createCellSizeDraggers( dtable, chandle$, cix );
        });
        return d8dFramework;




        //==========================================
        // //\\ cell size
        //==========================================
        function createCellSizeDraggers( dtable, chandle$, cix )
        {
            var ts = dtable.style8data_tpl;
            var land0port = ts.orientationLandscape ? 'landscape' : 'portrait';
            var colFr = ts.columnFractions[ land0port ];

            ///--------------------------------------------
            /// virtual point which we pretend to drag
            ///--------------------------------------------
            var pointWrap = {
                //.possibly must be a dom-descendant of
                //.dragSurface
                hand$       : chandle$,
                //.model which relates to drag
                widgetRack  : dtable,
                //.decoration
                makeCentralDiskInvisible : true,
                colFr : colFr,
                cix : cix,
            }
            ///----------------------------
            /// wrapping drag
            ///----------------------------

            achivedCellFractionArray[ cix ] = { fraction : colFr[ cix ] };

            d8dFramework.pointWrap_2_dragWrap({
                pointWrap           : pointWrap,
                doProcess           : doProcessCellSize,
                orientation         : 'x-axis',
                dragHandleDOM       : chandle$(),

                //stripping spinners ... until their garbage collection is
                //is implemented ... page template updates can create a garbage;
                no_spinner          : true,
                //update_decPoint     : false, //handleAction === 'corner-handle' && update_decPoint,
                //spinner_domParent   : pointWrap.hand$(),
            });

            //ccc( dtable.tableId + '-' + cix, chandle$(), colFr[ cix ] );

            ///------------------------------------------
            /// cell size: changes model
            ///------------------------------------------
            function doProcessCellSize( arg )
            {
                var dragWrap    = arg.dragWrap;
                var pointWrap   = dragWrap.pointWrap;
                var colFr       = pointWrap.colFr;
                var cix         = pointWrap.cix;
                var dtable      = pointWrap.widgetRack;
                var pmcard      = dtable.context;
                var ts          = dtable.style8data_tpl;
                switch( arg.down_move_up ) {
                    case 'up':
                        achivedCellFractionArray.forEach( (el,cix) => { el.fraction = colFr[ cix ] });
                        //if( cix === 0 && dtable.tableId === '0-0' )
                        //    ccc( 'up:' + JSON.stringify( achivedCellFractionArray ) );
                        pmcard.dtable.dat8tpl_2_model8inlcss();
                        if( pmcard.dtcard.dsg_id === 'pages-header' ) {
                            fmethods.oneheaderTpl_2_allPages_model8dom({ pmcard, skipOwn:true });
                        }

                        fmethods.set_userWork_unsavedFlag_in_CSS( !!'changed' );
                        break;
                    case 'move':
                            colFr[ cix ] = achivedCellFractionArray[ cix ].fraction +
                                           arg.surfMove[0] / ts.width;
                            //if( cix === 0 && dtable.tableId === '0-0' )
                            //    ccc( 'colFr='+colFr[ cix ] + ' columnFractions=' );

                            //renorms cell widths keeping width = const
                            var sum = colFr.reduce( ( (add, fr) => (add + fr) ), 0 );
                            for( var ix=0, len=colFr.length; ix<len; ix++ ) {
                                colFr[ix] /= sum;
                            }

                            /*
                            if( cix === 0 && dtable.tableId === '0-0' ) {
                                ccc( 'move: real sum='+ sum );
                                var ccff = '';
                                sum = 0;
                                colFr.forEach( cf => { ccff += cf.toFixed(3) + '\n';
                                sum+=cf;
                                ccc( 'innder loop: sum='+ sum.toFixed(3) );
                                })
                                ccc( 'move: colFr='+ccff + ' sum=' + sum );
                            }
                            */
                            dtable.dat8tpl_2_model8inlcss();
                         break;
                    default:
                }
            }
        }
        //==========================================
        // \\// cell size
        //==========================================



        //==========================================
        // //\\ creates font size and width dragger
        //==========================================
        function createSizeDragger( dtable )
        {
            ///--------------------------------------------
            /// virtual point which we pretend to drag
            ///--------------------------------------------
            var pointWrap = {
                //.possibly must be a dom-descendant of
                //.dragSurface
                hand$       : dtable.dom$.sizeHandle$,
                //.model which relates to drag
                widgetRack  : dtable,
                //.decoration
                makeCentralDiskInvisible : true,
            }
            ///----------------------------
            /// wrapping drag
            ///----------------------------
            var dragWrap = d8dFramework.pointWrap_2_dragWrap({
                pointWrap           : pointWrap,
                doProcess           : doProcess,
                orientation         : 'rotate',

                //todo ... this is broke when other d8d init disables corner-mover ... do fix this
                dragHandleDOM       : pointWrap.hand$(),

                //stripping spinners ... until their garbage collection is
                //is implemented ... page template updates can create a garbage;
                no_spinner          : true,
                //update_decPoint     : false, //handleAction === 'corner-handle' && update_decPoint,
                //spinner_domParent   : pointWrap.hand$(),

                //**************************************************
                // Vital.
                // Must clone this param to preserve it during drag.
                // Mouse wrappers return accomulated move only.
                achieved            : {
                                        width : dtable.style8data_tpl.width,
                                        fontSize : dtable.style8data_tpl.editableCss.fontSize,
                                      },
                //**************************************************
            });
            //ccc( 'dtable.style8data_tpl=', dtable.style8data_tpl );
            dtable.synchArchivedFont = function() {
                dragWrap.achieved.fontSize = dtable.style8data_tpl.editableCss.fontSize;
            }
        }
        ///------------------------------------------
        /// does model process
        ///------------------------------------------
        function doProcess( arg )
        {
            var dragWrap    = arg.dragWrap;
            var pointWrap   = dragWrap.pointWrap;
            var dtable      = pointWrap.widgetRack;
            var pmcard      = dtable.context;
            var ts          = dtable.style8data_tpl;
            switch( arg.down_move_up ) {
                case 'up':
                    //.vital to save start point;
                    //.must clone to preserve itsefl during drag;
                    dragWrap.achieved.width = ts.width;
                    dragWrap.achieved.fontSize = ts.editableCss.fontSize;
                    dtable.dat8tpl_2_model8inlcss();
                    if( pmcard.dtcard.dsg_id === 'pages-header' ) {
                        fmethods.oneheaderTpl_2_allPages_model8dom({ pmcard, skipOwn:true });
                    }

                    fmethods.set_userWork_unsavedFlag_in_CSS( !!'changed' );
                    break;

                case 'move':
                        ts.width = dragWrap.achieved.width +
                                   arg.surfMove[0];
                        ts.editableCss.fontSize = Math.max( 1,
                                    dragWrap.achieved.fontSize + arg.surfMove[1]/4 );
                        //if( dtable.tableId === '0-0' )
                        //    ccc( "ts.width="+ts.width.toFixed(3) );
                        pmcard.dtable.dat8tpl_2_model8inlcss();
                     break;
                default:
            }
        }
        //==========================================
        // \\// creates font size and width dragger
        //==========================================
    };
    //==========================================
    // \\// inits drag points
    //==========================================


}) ();

