import React, { Component } from 'react'
import $ from 'jquery'
import Alert from './alert.js'

class Connections extends Component {
    constructor(props) {
        super(props)
        this.state = {
            username: '',
            password: '',
            alertType: 'alert-danger',
            alertMessage: '',
            token: ''
        }
        this.getUser = this.getUser.bind(this)
        this.handleUser = this.handleUser.bind(this)
    }

    componentDidMount(){
		this.getUser()
	}

    getUser(){
		var success = this.handleUser.bind(this)
		$.ajax({
			method: 'GET',
			url: '/login'
		}).done(success)
    }
    
    handleUser(data){
		this.setState({
            username: data.email
        })
    }
    
    getToken(){
        var success = this.dataHandler.bind(this)
		var failure = this.errorHandler.bind(this)
        var url = '/token'
        var data = {
            'username': this.state.username,
            'password': this.state.password
        }
		$.ajax({
			method: 'POST',
			url: url,
			data: data
		}).done(success).fail(failure)
    }

    update(event){
        if (event.target.name === 'username'){
            this.setState({
                'username': event.target.value
            }) 
        } else if (event.target.name === 'password'){
            this.setState({
                'password': event.target.value
            })
        }
    }

    showAlert(message, type){
		this.setState({
			alertMessage: message,
			alertType: type,
		})
    }
    
    removeAlertHandler(){
		this.setState({
			alertMessage: null
		})
	}

    dataHandler(res){
        this.setState({
            token: res.access_token
        })
    }

    errorHandler(res){
        this.showAlert('Invalid password', 'alert-danger')
    }

    render() {
        var getToken = this.getToken.bind(this)
        var update = this.update.bind(this)
        var removeHandler = this.removeAlertHandler.bind(this)
        var hideToken = this.state.token ? false : true
        return (
			<div className='card'>
				<div className='card-header'>
					<span className='mt-4 mb-4 h4'>Connections</span>
				</div>
                <div className='card-body'>
                    <h5 className="card-title">Access Token</h5>
                    <p className="card-text">
                        Access Tokens are used to validate requests to our server without the need to log in as a user.
                        Only one Token may be active at a time.
                        Requesting a new token will deactivate any other active tokens. 
                        <b> Important: For security purposes, we do not store access tokens and they cannot be recovered.
                            If a token is forgotten or compromised, a new token must be issued.
                        </b>
                    </p>
                    <div className='input-group col'>
                        <input className='form-control col-2'
                            name='password' 
                            type='password'
                            placeholder='Enter Password'
                            onChange={update}
                        />
                        <div className='input-group-append'>
                            <button className='btn btn-outline-primary' 
                                type='submit'
                                onClick={getToken}
                                >Get New Token
                            </button>
                        </div>
                        <div className= 'mt-3' hidden={hideToken} >
                            <p className='text-success'>Here is your access token. Make sure to save this immediately in a secure place.</p>
                            <p className='text-danger'>
                                After leaving or refreshing this page, this token will no longer be accessible.
                            </p>
                            <span>{this.state.token}</span>
                        </div>
                        
                    </div>  
                </div>
                <Alert message={this.state.alertMessage} type={this.state.alertType} onExit={removeHandler} />
			</div>
		)
    }
}

export default Connections