import React, {Component} from 'react'
import $ from 'jquery'
import Alert from './alert.js'


class ForgotPassword extends Component{

	constructor(props){
		super(props)
		this.state = {
			alertMessage: '',
			alertType: ''
		}
		this.handleSubmitSuccess = this.handleSubmitSuccess.bind(this)
		this.handleSubmitError = this.handleSubmitError.bind(this)
	}

	submitHandler(event){
		event.preventDefault()
		var elements = event.target.elements
		var data = {
			email: elements.email.value
		}
		$.post('/forgot',data).done(this.handleSubmitSuccess).fail(this.handleSubmitError)
	}

	handleSubmitSuccess(){
		this.setState({
			alertMessage: 'An email has been sent with further instructions.',
			alertType: 'alert-success'
		})
	}

	handleSubmitError(){
		this.setState({
			alertMessage: 'Email is not registered.',
			alertType: 'alert-danger'
		})
	}

	removeAlert(){
		this.setState({
			errorMessage: null,
		})
	}

	render(){
		var removeAlert = this.removeAlert.bind(this)
		var onSubmit = this.submitHandler.bind(this)
		return (
			<div id='forgot-password'>
				<h1 className='display-4 mt-4 mb-4'>Forgot Password?</h1>
				<form className='mb-4' onSubmit={onSubmit}>
					<div className='form-group'>
						<label htmlFor='email'>Email</label>
						<input className="form-control" name="email" type="text"/>
					</div>
					<button type="submit" className="btn btn-primary">Send Email</button>
				</form>
				<Alert  message={this.state.alertMessage} type={this.state.alertType} onExit={removeAlert} />
			</div>
		)
	}
}

export default ForgotPassword