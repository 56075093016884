//import vertical_img from '../../bsl/core/d8d/img/vertical.png'
import vertical_img from '../../bsl/core/d8d/img/single-vertical-dividor.png'

( function() {
    var ns          = window.b$l;
    var $$          = ns.$$;
    var sn          = ns.sn;    
    var fapp        = sn('fapp' ); 
    var fconf       = ns.fconf;
    var fmethods    = sn('methods',fapp);
    var d8d_p       = sn('d8d-point',fmethods);
    var fheap       = ns.fapp.fheap;
    var currtpl     = ns.sn('currtpl', fconf);
    
    var aModel      = sn('aModel', fapp);
    var pgModels    = sn('pgModels', aModel, [] );
    var ccc         = console.log; ccc && ( ccc = console.log );

    fmethods.initCardsD8D_model                  = initCardsD8D_model;
    fmethods.initHorizontalDividorDragModel = initHorizontalDividorDragModel;
    return;








    //================================================
    // //\\ inits card shape controls: corner and move
    //================================================
    function initCardsD8D_model()
    {
        var dragSurface$ = aModel.pagesParent$;
        var d8dFramework = d8d_p.createFramework({
            dragSurface : dragSurface$(),
        });
        pgModels.forEach( (pm,pix) => {
            pm.pmcards.forEach( (pmcard,cardIx) => {
                create_corner8move_draggers( pmcard );
            });
        });
        fmethods.create_corner8move_draggers = create_corner8move_draggers;
        return d8dFramework;





        ///this function is to be exposed to facilitates draggers for
        ///card created from drag8drop from template
        function create_corner8move_draggers( pmcard )
        {
            if( fconf.ROLE === 'CONSTRUCTOR' ) {
                if( pmcard.dataGr.isChart || pmcard.dataGr.isPicture ) {
                    ////corner handle is only for cards-with-chart
                    create_corner8move_dragger( pmcard, 'corner-handle' );
                }
                create_corner8move_dragger( pmcard, 'vert' );
            }
        }

        //==========================================
        // //\\ creates page allocators
        //==========================================
        // //\\ creates wrap over draggee
        //------------------------------------------
        function create_corner8move_dragger( pmcard, handleAction )
        {
            //--------------------------------------------
            // //\\ virtual point
            //      which we pretend to drag
            //--------------------------------------------
            var pointWrap = {
                hand$       : handleAction ==='corner-handle' ?
                                pmcard.cardDom$$.horizDrgHand$ :
                                pmcard.cardDom$$.vertDrgHand$
                                ,
                widgetRack  : pmcard,
                makeCentralDiskInvisible : true,
                handleAction : handleAction,

                //these params can be confusing, they are
                //added via pointWrap, not via pointWrap_2_dragWrap;
                spinnerClsId        : 'card-overlay-spinner-' + pmcard.pageIx + '-' + pmcard.cardIx,
                dragDecorColor      : fconf.CP_OVERLAYS_MASTER_COLOR,
            }
            //pointWrap.orientation = orientation; //patch as pointWrap itself ...
            //----------------------------
            // \\// virtual point
            //----------------------------



            //----------------------------
            // //\\ wrapping drag
            //----------------------------
            pointWrap.widgetRack.dragWrap
                = //note: creates circular dependence
            d8dFramework.pointWrap_2_dragWrap({
                pointWrap           : pointWrap,
                doProcess           : doProcess,
                orientation         : 'rotate',
                dragHandleDOM       : pointWrap.hand$(),

                //stripping spinners ... until their garbage collection is
                //implemented ... page template updates can create a garbage;
                //spinner looks ugly on mover ... skipping its creation
                no_spinner          : true, //handleAction !== 'corner-handle',
                //update_decPoint     : false, //handleAction === 'corner-handle' && update_decPoint,
                //spinner_domParent   : pointWrap.hand$(),

                //************************************************
                //Vital. Keeps start pos. Mouse wrappers return
                //       accomulated move only.
                //------------------------------------------------
                //Must clone to preserve itself during drag
                achieved            : pmcard.dtcard.pos.concat([]),
                //************************************************
            });

            ///this thing is used only for page-headers move anti-jerk synchronization:
            ///     two cooks on the pot: archived-d8d-pos and dtcard.pos:
            pmcard.synchArchivedParams = function() {
                //ccc( pmcard.pageIx + ' ' + pmcard.cardIx +
                //     ' does synch archived for pmcard.dtcard.pos', pmcard.dtcard.pos );
                ns.paste( pointWrap.widgetRack.dragWrap.achieved, pmcard.dtcard.pos );
            }
            //----------------------------
            // \\// wrapping drag
            //----------------------------
        }
        //------------------------------------------
        // \\// creates wrap over draggee
        //------------------------------------------


        //------------------------------------------
        // //\\ does process corner-handle
        //------------------------------------------
        function doProcess( arg )
        {
            var dragWrap    = arg.dragWrap;
            var pointWrap   = dragWrap.pointWrap;
            var widgetRack  = pointWrap.widgetRack;
            var pg          = pgModels[ widgetRack.pageIx ];
            var cpos        = widgetRack.dtcard.pos;
            var achieved    = dragWrap.achieved;
            var pmcard      = pg.pmcards[ widgetRack.cardIx ];
            var dataId      = pmcard.dtcard.dataId;
            var dsg_id      = pmcard.dtcard.dsg_id;
            //ccc( pmcard.pageIx + ' ' + pmcard.cardIx +
            //     ' draggin ... ', pmcard.dtcard.pos,
            //     ' * archived=', pointWrap.widgetRack.dragWrap.achieved );

            switch( arg.down_move_up ) {
                case 'up':
                    //.vital to save start point
                    //Must clone to preserve itsefl during drag
                    dragWrap.achieved = cpos.concat([]);
                    fmethods.card__internals({ pmcard });
                    fmethods.inlcss__doResizeCardFrames({ pmcard });
                    if(
                        ( dataId === "cells-bound" && dsg_id === 'pages-header' ) ||
                        ( dataId === "horizontal-line" && dsg_id === 'pages-header' )
                    ) {
                    //todo if( dsg_id === 'pages-header' ) {
                        pmcard.dtable.dat8tpl_2_model8inlcss();
                        fmethods.oneheaderTpl_2_allPages_model8dom({ pmcard, skipOwn:true });
                    } else if( pmcard.dtcard.isPicture && dsg_id === 'pages-header' ) {
                        fmethods.oneheaderTpl_2_allPages_model8dom({ pmcard, skipOwn:true });
                    } else if( pmcard.dtcard.isTable ) {
                    //todo } else if( dsg_id === 'cells-bound' ) {
                        //fmethods.rebuildTable( pmcard.dtable, null, 'do inline css' );
                        //todm ... seems vital for preserving table-css-width ... why?
                        //todo ... pmcard.dtable ... why missed?
                        pmcard.dtable.dat8tpl_2_model8inlcss();
                    }

                    fmethods.set_userWork_unsavedFlag_in_CSS( !!'changed' );
                    break;

                case 'move':
                        var dt = currtpl.docbody;
                        var move2percent = 100
                                           / fheap.editedPageScale
                                           / dt.pstyle.PAGE_WIDTH;
                        if( pg.parentColumnsCount && pg.parentColumnsCount > 1 ) {
                            ////todm ... change this patch
                            move2percent *= 2.5;  //todm ... remove this patch
                        }
                        var coor = pointWrap.handleAction === 'corner-handle' ? [2,3] : [0,1];
                        cpos[coor[0]] = achieved[coor[0]] + arg.surfMove[0] * move2percent;
                        cpos[coor[1]] = achieved[coor[1]] + arg.surfMove[1] * move2percent
                                        * 0.75; //todm ... remove this patch
                        fmethods.dtcardPos_2_cssModel({ pmcard });
                        fmethods.inlcss__doResizeCardFrames({ pmcard });
                     break;
                default:
            }
        }
        //------------------------------------------
        // \\// does process corner-handle
        //==========================================
        // \\// creates page allocators
        //==========================================
    }
    //================================================
    // //\\ inits card shape controls: corner and move
    //================================================










    //==========================================
    // //\\ inits page-zone-scale slider handle
    //==========================================
    function initHorizontalDividorDragModel()
    {
        var dragSurface$ = aModel.pagesSuperparent$; //aModel.pagesParent$;
        var d8dFramework = d8d_p.createFramework({
            dragSurface : dragSurface$(),
        });
        createHorizDividor();
        return d8dFramework;



        //==========================================
        // //\\ creates horiz dividor
        //==========================================
        // //\\ creates wrap over draggee
        //------------------------------------------
        function createHorizDividor()
        {
            var hoirzDividor$ = $$
                .dct( 'horiz-dividor-handle', dragSurface$ )
                .css( 'left', (currtpl.docbody.pstyle.PAGE_WIDTH/2).toFixed() + 'px' )
                .ch( $$
                    .img()
                    .cls( 'img')
                    .a( 'src', vertical_img )
                );
            // //\\ virtual point
            //      which we pretend to drag
            //--------------------------------------------
            var pointWrap = {
                hand$       : hoirzDividor$,
                //widgetRack  : firstPage,
                makeCentralDiskInvisible : true,
                currentRightPos : 0,

                //these params can be confusing, they are
                //added via pointWrap, not via pointWrap_2_dragWrap;
                spinnerClsId        : 'hor-div-spinner',
                dragDecorColor      : fconf.CP_OVERLAYS_MASTER_COLOR,

            }
            //----------------------------
            // \\// virtual point
            //----------------------------

            //=============================================================
            // //\\ updates decoration spinner
            //=============================================================
            function update_decPoint( decPoint )
            {
                var divbox = hoirzDividor$.box();
                decPoint.style.left = (divbox.width/2).toFixed(3) + 'px';
                decPoint.style.top = (divbox.height/2).toFixed(3) + 'px';
                decPoint.style.width = divbox.width + 'px';
                decPoint.style.height = divbox.height + 'px';
            }
            //=============================================================
            // \\// updates decoration spinner
            //=============================================================

            //----------------------------
            // //\\ wrapping drag
            //----------------------------
            d8dFramework.pointWrap_2_dragWrap({
                pointWrap           : pointWrap,
                doProcess           : doProcessHoriz,
                dragHandleDOM       : pointWrap.hand$(),
                update_decPoint     : update_decPoint,
                tooltip             : 'move left to zoom out',
                spinner_domParent   : pointWrap.hand$(),
                //************************************************
                //Vital. Keeps start pos. Mouse wrappers return
                //       accomulated move only.
                //------------------------------------------------
                //Must clone now if array or object to preserve itself
                //during drag then. No need to clone if a primitive.
                achieved            : pointWrap.currentRightPos,
                //************************************************
            });
            //----------------------------
            // \\// wrapping drag
            //----------------------------
        }
        //------------------------------------------
        // \\// creates wrap over draggee
        //------------------------------------------

        //------------------------------------------
        // //\\ does process horiz div
        //------------------------------------------
        function doProcessHoriz( arg )
        {
            var dragWrap = arg.dragWrap;
            var achieved = dragWrap.achieved;
            var pagesSuper$ = aModel.pagesSuperparent$; //$$.q('.pages-superparent');
            var super_box = pagesSuper$().getBoundingClientRect();

            switch( arg.down_move_up ) {
                case 'up':
                    dragWrap.achieved = aModel.dividorRightGap_frac;
                    fmethods.dcss__pages8content();
                    break;
                case 'move':
                        //todo as of now, the dividor handle moves becaus it "scales", its
                        //(global) css-pos is fixed!
                        aModel.dividorRightGap_frac =
                            achieved - arg.surfMove[0] / super_box.width
                            * 2 //todo patch
                        fmethods.mcss__dashb_detects_condensityScale();
                        fmethods.mcss__dashb_frames_detectsHeights();
                        fmethods.dcss__dashb_someFrames();
                        fmethods.dcss__pages8content();
                     break;
                default:
            }
        }
        //------------------------------------------
        // \\// does does process horiz div
        // \\// creates horiz dividor
        //==========================================
    };
    //==========================================
    // \\// inits page-zone-scale slider handle
    //==========================================


}) ();

