import "./page-all.js";

var ns          = window.b$l;
var fapp        = ns.fapp;
var fmethods    = ns.sn('methods', fapp);
var aModel      = ns.sn('aModel', fapp);
var pgModels    = ns.sn('pgModels', aModel, [] );
var ccc         = console.log; ccc && ( ccc = console.log );

fmethods.dcss__pages8content      = dcss__pages8content;



function dcss__pages8content()
{
    pgModels.forEach( ( pm,pix ) => {
        fmethods.inlcss__page8cardsFrames( pm );
        pm.pmcards.forEach( pmcard => {
            if( pmcard.dtable ) {
                pmcard.dtable.dat8tpl_2_model8inlcss();
            }
        });

    });
}

