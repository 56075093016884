
export default function ( HRM, pstyle ) {
    //debugger
    return  {
        xAxis: {
            categories: [], //dates
        },
        series:
        [
            {
                type: 'line',
                name: 'spO2',
                data: [], //severity
                METAOPT_data_report_keys :
                [
                    'SPO2_MIN',
                ]
            },
        ],
    }
}




