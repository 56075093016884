
///works in tandem with FontDecorations.js

( function() {
    var ns          = window.b$l;
    var sn          = ns.sn;    
    var fapp        = sn('fapp' ); 
    var fmethods    = ns.sn('methods', fapp);
    var tasksInProgress = ns.sn( 'tasksInProgress', ns.fheap, [] );
    var ccc         = console.log; ccc && ( ccc = console.log );

    fmethods.setsup_styleEditor        = setsup_styleEditor;
    fmethods.style__modelValue2cssValue = style__modelValue2cssValue;
    fmethods.styleProperty_2_htmlEl = styleProperty_2_htmlEl;
    fmethods.finalizes_tasksInProgress = finalizes_tasksInProgress;
    return;




    ///sets up styles editor controls
    /// populates controls from styles-model
    ///     elStyles_2_decorControls
    ///     provides callback to controls to update HTMLs from controls
    ///     extends time of availability of controls: bouncedDecorationControlsDisabler
    function setsup_styleEditor({
        controlsCssPath,    //like '.content-decoration-controls' );
        styleTpl,           //existing styles-repository ... like 
                            //.............
        htmlEl$,            //optional
        styles2html,        //optional
        updateHeadersIfAny, //optional
    }) {

        if( ns.fconf.ROLE !== 'CONSTRUCTOR' ) return; //edit is only for admin
        var dc = document.querySelector( controlsCssPath );
        dc.style.display = 'inline';

        fmethods.bouncedDecorationControlsDisabler();
        ///takes existing styles-repository for given htmlEl and ...
        var styles = ns.clonetree( styleTpl );
        //.supplies styling-callback which will run when styling-controls changed by user
        styles.styles_2_tpl8html = ({ propName,propValue }) => {
            //.used as modelbox-wise update
            styleTpl[ propName ] = propValue;
            if( styles2html ) {
                ////used as tablewise update
                styles2html();
            } else {
                //.used as html-box-wise update
                styleProperty_2_htmlEl({
                    propName,
                    propValue,
                    htmlEl$,
                });
            }
            if( updateHeadersIfAny ) {
                ////used as pageheader-wise update
                updateHeadersIfAny();
            }
        };
        //... and supplies them and styling-callback
        //to top Control Panel styling-controls
        fmethods.elStyles_2_decorControls___setState( styles );

        tasksInProgress.push( fmethods.doDisableDecorationControls );
    }

    ///sets single property CSS
    function styleProperty_2_htmlEl({
        propName,
        propValue,
        htmlEl$,
    }) {
        var {propName_css, propValue_css} = style__modelValue2cssValue({ propName, propValue, });
        htmlEl$.css( propName_css, propValue_css );

        //ccc( 'changing css for  htmlEl$ parent=',  htmlEl$().parentNode );

        //todo: mess is here: this setting does not show in GUI, but shown in this debug:
        //if( propName === 'fontWeight' )
        //    ccc( 'stand alone style is set to ' + htmlEl$._css( propName_css ) + ' el=', htmlEl$() );
    }

    ///converts model to CSS
    function style__modelValue2cssValue({ propName, propValue, })
    {
        //if( propName === 'fontWeight' )
        //    ccc( 'in the heart: propValue=' + propValue + ' must result to bold' );
        var propValue_css = propValue;
        var propName_css = propName;
        switch( propName ) {
            case 'fontSize' :
                propValue_css = propValue + 'px';
                break;
            case 'lineHeight' :
                propValue_css = propValue.toFixed(2);
                break;
            case 'fontColor' : propName_css = 'color';
                break;
            case 'backgroundColor' : propName_css = 'background-color';
                break;
            case 'fontWeight' : propValue_css = propValue ? 'bold' : 'normal';
                break;
            case 'fontStyle' : propValue_css = propValue ? 'italic' : 'normal';
                break;
            case 'borderWidth' :
                propValue_css = propValue + 'px';
                break;
            default :
        }
        return {propName_css, propValue_css};
    }


    function finalizes_tasksInProgress()
    {
        tasksInProgress.forEach( fun => fun() );
        tasksInProgress.length = 0;
        //.closes open astray editor
        fmethods.doDisableDecorationControls();
    }

}) ();

