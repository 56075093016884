
( function() {
    var ns	        = window.b$l;
    var $$          = ns.$$;
    var fapp        = ns.fapp;
    var fheap       = ns.sn('fheap', fapp);
    var fmethods    = ns.sn('methods', fapp);
    var aModel      = ns.sn('aModel', fapp);
    var fconf       = ns.fconf;
    var tplpopul    = ns.sn('tplpopulation', fconf);
    var currtpl     = ns.sn('currtpl', fconf);
    var ccc         = console.log; ccc && ( ccc = console.log );

    fheap.changesNotSaved = false;

    fmethods.mcss__dashb_detects_condensityScale = mcss__dashb_detects_condensityScale;
    fmethods.set_userWork_unsavedFlag_in_CSS = set_userWork_unsavedFlag_in_CSS;
    fmethods.update_unsavedFlag_in_CSS = update_unsavedFlag_in_CSS;
    fmethods.setChosenAsDefault = setChosenAsDefault;
    fmethods.dashModel_of_pagePosModel = dashModel_of_pagePosModel;
    return;








    function set_userWork_unsavedFlag_in_CSS( changed, targetTpl )
    {
        //-----------------------------------
        // //\\ makes single-doc change
        //-----------------------------------
        targetTpl = targetTpl || tplpopul[ currtpl.docid ];
        //ccc( 'setting: ' + targetTpl.docid + ' changed=' + changed );
        targetTpl.unsavedToDb = changed;
        //-----------------------------------
        // \\// makes single-doc change
        //-----------------------------------

        //-----------------------------------
        // //\\ checks doc-populateion change
        //-----------------------------------
        var someChanged = false;
        ns.eachprop( tplpopul, (tpl,docid) => {
            /*
            if( tpl.docid === targetTpl.docid ) {
                tpl.unsavedToDb = targetTpl.unsavedToDb;
            }
            */
            //ccc( 'checking each: docid=' + tpl.docid + ' changed=', tpl.unsavedToDb );
            if( tpl.unsavedToDb ) {
                someChanged = true;
            }
        })
        fheap.changesNotSaved = someChanged;
        //-----------------------------------
        // \\// checks doc-populateion change
        //-----------------------------------

        update_unsavedFlag_in_CSS();
    }



    function update_unsavedFlag_in_CSS()
    {
        var docsSelector$ = ns.haz( aModel, 'docsSelector$' );
        if( docsSelector$ ) {
            aModel.docsSelector$.removeClass( 'doc-unsaved-to-db' );
        }
        var pagesSuperparent$ = ns.haz( aModel, 'pagesSuperparent$' );
        if( pagesSuperparent$ ) {
            aModel.pagesSuperparent$.removeClass( 'doc-unsaved-to-db' );
        }
        ns.eachprop( tplpopul, (tpl,docid) => {
            var opt$ = $$.q( '.doc-selection-option-'+docid );
            if( opt$() ) {
                if( tpl.unsavedToDb ) {
                    opt$.addClass( 'doc-unsaved-to-db' );
                } else {
                    opt$.removeClass( 'doc-unsaved-to-db' );
                }
            }
            ///highlights doc-select-html-element if chosen is changed
            if( docsSelector$ ) {
                if( tpl.docbody.chosenInDash ) {

                    //alternative ways ?
                    //aModel.docsSelector$.a( 'value', tpl.docid );
                    //aModel.docsSelector$().value = tpl.docid;

                    if( tpl.unsavedToDb ) {
                        aModel.docsSelector$.addClass( 'doc-unsaved-to-db' );
                    }
                }
            }

            ///highlights doc-select-html-element if chosen is changed
            if( pagesSuperparent$ ) {
                if( tpl.docbody.chosenInDash ) {
                    if( tpl.unsavedToDb ) {
                        pagesSuperparent$.addClass( 'doc-unsaved-to-db' );
                    }
                }
            }

        })
        var proot$ = $$.q('.plugin-root');
        if( proot$() ) {
            ////when doc template is arrived, then plagin GUI may be not yet ready
            if( fheap.changesNotSaved ) {
                proot$.addClass( 'changes-not-saved' );
            } else {
                proot$.removeClass( 'changes-not-saved' );
            }
        }
    }













    ///prevents condensed pages from becoming too small and
    ///to overflow right pages container edge
    function restricts_rightGapDividorValues()
    {
        var minPageWidth_frac = 100/aModel.pagesSuperparent$.box().width;
        aModel.dividorRightGap_frac = Math.max(
            0.0, //prevents fraction to become smaller than this number
            Math.min(
                //prevents fraction to become greater than this number
                1-minPageWidth_frac, 

                aModel.dividorRightGap_frac
        ));
    }



    ///==================================================
    /// manages layout
    ///==================================================
    function mcss__dashb_detects_condensityScale()
    {
        //------------------------------------------
        // //\\ spawns user-preset page width
        //------------------------------------------
        restricts_rightGapDividorValues();
        var occupiedFraction = Math.max( 0.001, 1 - aModel.dividorRightGap_frac );
        var fractCount = 1/occupiedFraction;
        fheap.pagesColumnCount = Math.max( 1, Math.floor( fractCount) );
        var reminder = Math.max( 0, 1 - fheap.pagesColumnCount * occupiedFraction ); 
        //------------------------------------------
        // \\// spawns user-preset page width
        //------------------------------------------

        //------------------------------------------
        // //\\ detects dimensions
        //------------------------------------------
        var pagesPaneWidth = aModel.pagesSuperparent$.box().width;
        //------------------------------------------
        // \\// detects dimensions
        //------------------------------------------

        //------------------------------------------
        // //\\ establishes scale
        //------------------------------------------
        //makes page fit the space left
        //when borders, gaps, "margins" subtracted
        fheap.editedPageScale =
            ( pagesPaneWidth * (1 - reminder )
              - fheap.pagesColumnCount * 
                    (fconf.DASHBOARD.GAP_BETWEEN_PAGES +
                     fconf.DASHBOARD.PAGE_BORDER_WIDTH * 2
                    )
              - fconf.DASHBOARD.PAGES_LEFT_MARGIN
              - fconf.DASHBOARD.PAGES_RIGHT_MARGIN
            ) / ( currtpl.docbody.pstyle.PAGE_WIDTH * fheap.pagesColumnCount );
        //------------------------------------------
        // \\// establishes scale
        //------------------------------------------
        //ccc( Date.now() + ' scale=' + fheap.editedPageScale );
    }



    function setChosenAsDefault( value, targetTpl )
    {
        value = !!value;
        targetTpl = targetTpl || tplpopul[ currtpl.docid ];
        var toCheck = !!targetTpl.docbody.chosenAsDefault;

        targetTpl.docbody.chosenAsDefault = value;
        if( value !== toCheck ) {
            set_userWork_unsavedFlag_in_CSS( !!'changed', targetTpl );
        }

        //unsets others:
        ns.eachprop( tplpopul, (tpl,docid) => {
            if( targetTpl.docid === tpl.docid ) return;
            if( !tpl.docbody.chosenAsDefault ) return;
            tpl.docbody.chosenAsDefault = false;
            set_userWork_unsavedFlag_in_CSS( !!'changed', tpl )
        })
    }



    ///pagesColumnCount_2_virtualPagePosition:    
    ///based on fheap.pagesColumnCount, prepares 
    ///model's unscaled "virtual" positions of pages: no dotection performed
    function dashModel_of_pagePosModel( pm )
    {
        var col = pm.pageIx % fheap.pagesColumnCount;
        var row = ( pm.pageIx - col ) / fheap.pagesColumnCount;

        var pstyle = currtpl.docbody.pstyle;
        //.does position page horizontally including gab between pages
        pm.pageLeft = fconf.DASHBOARD.PAGES_LEFT_MARGIN
                      + col * ( pstyle.PAGE_WIDTH + fconf.DASHBOARD.GAP_BETWEEN_PAGES );
        //.does position page vertically including gab between pages
        pm.pageTop = row * pstyle.PAGE_HEIGHT +
                     (row ? fconf.DASHBOARD.PAGE_MARGIN_TOP_PX : 0 );
        fheap.editedPageTop = ns.sn( 'editedPageTop', fapp.fheap, [] );
        fheap.editedPageLeft = ns.sn( 'editedPageLeft', fapp.fheap, [] );
        fheap.editedPageTop[ pm.pageIx ] = [ pm.pageLeft, pm.pageTop ];
    }

}) ();




