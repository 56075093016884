import React, {Component} from 'react'
import $ from 'jquery'
import Alert from './alert'


class ResetPassword extends Component {

	constructor(props){
		super(props)
		this.state = {
			message: '',
			status: ''
		}
		this.handleSuccess = this.handleSuccess.bind(this)
		this.handleError = this.handleError.bind(this)
	}

	submitHandler(event){
		event.preventDefault()
		var elements = event.target.elements
		var password = elements.password.value
		var confirm = elements.confirm.value
		var url = '/reset' + this.props.location.location.search
		if (password === confirm){
			$.post(url,{
				password: password
			}).done(this.handleSuccess).fail(this.handleError)
		} else {
			this.setState({
				message: 'Passwords do not match.',
				status: 'alert-danger'
			})
		}		
	}

	handleSuccess(){
		this.setState({
			message: 'Password successfully reset.',
			status: 'alert-success'
		})
	}

	handleError(){

	}

	removeAlert(){
		this.setState({
			errorMessage: null,
		})
	}

	render(){
		var removeAlert = this.removeAlert.bind(this)
		var onSubmit = this.submitHandler.bind(this)
		return (
			<div id='reset-password'>
				<h1 className='display-4 mt-4 mb-4'>Reset Password</h1>
				<form className='mb-4' onSubmit={onSubmit}>
					<div className='form-group'>
						<label>New Password</label>
						<input className="form-control" name="password" type="password"/>
					</div>
					<div className='form-group'>
						<label>Confirm Password</label>
						<input className="form-control" name="confirm" type="password"/>
					</div>
					<button type="submit" className="btn btn-primary">Submit</button>
				</form>
				<Alert  message={this.state.message} type={this.state.status} onExit={removeAlert} />
			</div>
		)
	}
}

export default ResetPassword