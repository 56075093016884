import React, { useCallback, useEffect, useState } from "react";
import { Modal, Select, Button, Input, Checkbox } from "antd";
import { RgbaColorPicker } from "react-colorful";
import { rgbaToHex } from "./utils";
import { colord } from "colord";
import { globalData } from "./globals";

const ChartSettingsModal = ({
  chartSettingsModal,
  setChartColors,
  switchCharts,
  addCharts,
  chartRanges,
  setChartRanges,
  chartColors,
  setChartSettingsModal,
  selectedCharts,
  getAutoScale,
  updateChartsOrder,
  addHistoryEvent,
  additionalLines,
  signalPairs,
  chartSizes,
  gridLines,
  setChartSizes,
  setChartGrid,
}) => {
  const [color, setColor] = useState(chartColors[chartSettingsModal.SignalId] || "#b32aa9");
  const [range, setRange] = useState(chartRanges[chartSettingsModal.SignalId] || { min: 0, max: 1000 });
  const [scale, setScale] = useState(null);
  const [size, setSize] = useState(100);
  const [showGrid, setShowGrid] = useState(false);

  const saveChartSettings = useCallback(
    (chartSettingsModal) => {
      const signalId = chartSettingsModal.SignalId;
      const historyEventDataNext = { signalId: signalId };
      const historyEventDataPrev = { signalId: signalId };
      let newCharts = null;
      if (chartSettingsModal.visible) {
        if (chartSettingsModal.used) {
          if (chartSettingsModal.oldRow !== chartSettingsModal.row) newCharts = switchCharts(chartSettingsModal.oldRow, chartSettingsModal.row);
        } else {
          newCharts = addCharts(parseInt(signalId), chartSettingsModal.row);
        }
        historyEventDataPrev.charts = selectedCharts;
        historyEventDataNext.charts = newCharts;
      } else {
        if (chartSettingsModal.used) {
          const charts = selectedCharts.filter((t, i) => i !== chartSettingsModal.row);
          updateChartsOrder(charts);
          historyEventDataPrev.charts = selectedCharts;
          historyEventDataNext.charts = charts;
        }
      }
      if (range.min !== chartRanges[signalId].min || range.max !== chartRanges[signalId].max) {
        historyEventDataPrev.range = chartRanges[signalId];
        historyEventDataNext.range = range;
        const c = { [signalId]: range };
        if (additionalLines[signalId]) {
          c[additionalLines[signalId]] = range;
        } else {
          if (additionalLines[signalPairs[signalId]]) {
            c[signalPairs[signalId]] = range;
          }
        }
        console.log(c);
        setChartRanges(c);
      }
      if (color !== chartColors[signalId]) {
        historyEventDataPrev.color = chartColors[signalId];
        historyEventDataNext.color = color;
        setChartColors(signalId, color);
      }
      if (size / 100 !== chartSizes[signalId]) {
        setChartSizes({ ...chartSizes, [signalId]: size / 100 });
      }
      if (showGrid !== globalData.gridLines[chartSettingsModal.signalId]) {
        globalData.gridLines[chartSettingsModal.SignalId] = showGrid;
        setChartGrid(chartSettingsModal.SignalId);
      }
      setChartSettingsModal(false);
      addHistoryEvent({
        type: "chartSettings",
        prev: historyEventDataPrev,
        next: historyEventDataNext,
        title: "chart settings change",
      });
    },
    [
      setChartSettingsModal,
      switchCharts,
      addCharts,
      selectedCharts,
      updateChartsOrder,
      color,
      additionalLines,
      setChartGrid,
      showGrid,
      signalPairs,
      setChartRanges,
      chartRanges,
      setChartColors,
      chartColors,
      addHistoryEvent,
      range,
      size,
      chartSizes,
      setChartSizes,
    ]
  );

  const autoScale = useCallback(() => {
    if (scale) setRange(scale);
  }, [scale]);

  useEffect(() => {
    if (!chartSettingsModal.SignalId) return;
    setScale(getAutoScale(chartSettingsModal.SignalId));
    setColor(chartColors[chartSettingsModal.SignalId]);
    setRange(chartRanges[chartSettingsModal.SignalId]);
    setSize(parseInt(chartSizes[chartSettingsModal.SignalId] * 100));
    setShowGrid(globalData.gridLines[chartSettingsModal.SignalId]);
  }, [getAutoScale, chartSettingsModal.SignalId, setColor, chartColors, chartSizes, chartRanges, gridLines]);

  const updateColor = useCallback(
    (color1) => {
      color1 = colord(color1).toHex();
      if (color1.length === 7) color1 += "ff";
      setColor(color1);
      document.getElementById("color_picker_small").style.background = color1;
    },
    [setColor]
  );

  return (
    <Modal
      title={"Configure signal: " + chartSettingsModal?.name}
      centered
      open={Boolean(chartSettingsModal)}
      onOk={() => {
        saveChartSettings(chartSettingsModal);
        setScale(null);
      }}
      onCancel={() => {
        // addEvent(true);
        setChartSettingsModal(false);
        setScale(null);
      }}
    >
      <div className="chartSettings">
        <div className="chartSettingsFields">
          <div className="chartSettingsFieldsRow">
            <span className="chartSettingsFieldLabel">Signal Color</span>
            <span
              onClick={() => {
                document.getElementById("color_picker_container").style.display = "block";
              }}
              id="color_picker_small"
              style={{ backgroundColor: chartSettingsModal.color }}
            ></span>
          </div>
          <div className="chartSettingsFieldsRow">
            <span className="chartSettingsFieldLabel">Display row</span>
            <span>
              <Select
                key={chartSettingsModal.type + chartSettingsModal.row}
                options={[...Array(selectedCharts.length).keys()].map((i) => ({ label: i, value: i }))}
                onChange={(value, option) => {
                  setChartSettingsModal({ ...chartSettingsModal, row: value });
                }}
                defaultValue={chartSettingsModal.row}
              />
            </span>
          </div>
          <div className="chartSettingsFieldsRow">
            <span className="chartSettingsFieldLabel">Chart Size</span>
            <span>
              <Select
                key={chartSettingsModal.type + chartSettingsModal.row}
                options={[100, 125, 150, 200].map((i) => ({ label: i + "%", value: i }))}
                onChange={(value, option) => {
                  setSize(parseInt(value));
                }}
                style={{ width: "100px" }}
                value={size}
              />
            </span>
          </div>
          <div className="chartSettingsFieldsRow">
            <span className="chartSettingsFieldLabel">Is visible</span>
            <span>
              <Checkbox
                checked={chartSettingsModal.visible}
                onChange={(e) => {
                  setChartSettingsModal({ ...chartSettingsModal, visible: e.target.checked });
                }}
              />
            </span>
          </div>
          <div className="chartSettingsFieldsRow">
            <span className="chartSettingsFieldLabel">GridLines</span>
            <span>
              <Checkbox
                checked={showGrid}
                onChange={(e) => {
                  setShowGrid(e.target.checked);
                }}
              />
            </span>
          </div>
          <div className="chartSettingsFieldsRow">
            <span className="chartSettingsFieldLabel">Chart top value</span>
            <span>
              <Input
                style={{ width: "100px" }}
                disabled={!chartSettingsModal.used || chartSettingsModal?.name === "SPO2"}
                value={Math.floor(range?.max)}
                onChange={(e) => setRange({ ...range, max: parseFloat(e.target.value) })}
                type="number"
              />
            </span>
          </div>
          <div className="chartSettingsFieldsRow">
            <span className="chartSettingsFieldLabel">Chart bottom value</span>
            <span>
              <Input
                style={{ width: "100px" }}
                disabled={!chartSettingsModal.used}
                value={Math.floor(range?.min)}
                onChange={(e) => setRange({ ...range, min: parseFloat(e.target.value) })}
                type="number"
              />
            </span>
          </div>
          {chartSettingsModal.used && scale && (
            <div>
              <Button
                onClick={() => {
                  autoScale();
                }}
              >
                Autoscale
              </Button>
            </div>
          )}
        </div>
        <div id="color_picker_container">
          <RgbaColorPicker color={colord(color).toRgb()} onChange={updateColor} />
        </div>
      </div>
    </Modal>
  );
};

export default ChartSettingsModal;
