
var ns          = window.b$l;
var fapp        = ns.fapp;
var fheap       = ns.sn('fheap', fapp);
var fmethods    = ns.sn('methods', fapp);
var aModel      = ns.sn('aModel', fapp);
var ccc         = console.log; ccc && ( ccc = console.log );

fmethods.dash8doc__internals = dash8doc__internals;
fmethods.docModel_8_pagesCardsInterns              = docModel_8_pagesCardsInterns;
fmethods.injectionCondition         = injectionCondition;
fmethods.cards__internals                 = cards__internals;







///------------------------------------------------------------------------------        
///rebuilds plugin
///------------------------------------------------------------------------------        

function dash8doc__internals()
{
    aModel.pluginRoot$ = ns.$$.q( '.plugin-root' );

    //doc-tpl
    fmethods.doc__internals();

    //dashboard
    fmethods.modelify8containify_rightB_icons8masks();
    fmethods.inldynCSS_CP();
    //fmethods.updateRightSideBar();
    fmethods.populatePagesIcons();
    fmethods.mdcss__dashb();

    //------------------------------------------
    // //\\ adds version to user's screen
    //------------------------------------------
    if( !fapp.hasOwnProperty( 'version$' ) ) {
        fapp.version$ =
            ns.$$.span()
                .addClass( 'report-composer-version' )
                .a( 'title', 'Report Composer Version ' + ns.fconf.version )
                .html( 'Version ' + ns.fconf.version )
                ;
    }
    fapp.version$.to( aModel.pluginRoot$ )
    //------------------------------------------
    // \\// adds version to user's screen
    //------------------------------------------
}




function docModel_8_pagesCardsInterns()
{
    //ccc( 'docModel_8_pagesCardsInterns() starts ... ' );
    fmethods.doc__dataModel();

    aModel.pgModels.forEach( pgModel => {
        cards__internals( pgModel );
    })
    //pages__internals does similar job, but does a lot more: d8d,dom_pgFrames, css__page/cards
    /*
    aModel.initDragModel_d8draw
    function page__internals( pgModel )
    {
        fmethods.dom__pageFrame8cardFrames({ pgModel });
        //.todm find out why this cannot live after following line for charts
        fmethods.cards__internals( pgModel );
        fmethods.inlcss__page8cardsFrames( pgModel )
    }
    */
}

function cards__internals( pgModel )
{
    pgModel.pmcards.forEach( pmcard => {
        fmethods.card__internals({ pmcard }); // this thing has switches inside that
                                        // prepare dumb PDF-download and skip GUI-dashboard
    })
}

function injectionCondition()
{
    return fheap.tplDownloadState === 'completed' &&
           ns.fheap.imagesLoadCount === ns.fheap.imagesLoadMust;
}

