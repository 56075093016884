//Pulse Events (# Events In Ranges)                                                 

export default function ( HRM, pstyle ) {
    return  {
        xAxis: {
            categories: [],
        },
        series:
        [
            {
                type: 'line',
                name: '>=90',
                data: [],
                METAOPT_data_report_keys :
                [
                    'AA_GTE90COUNT',
                ]
            },

            {
                type: 'line',
                name: '80-89',
                data: [],
                METAOPT_data_report_keys :
                [
                    'AA_GTE80LT90COUNT',
                ]
            },

            {
                type: 'line',
                name: '70-79',
                data: [],
                METAOPT_data_report_keys :
                [
                    'AA_GTE70LT80COUNT',
                ]
            },

            {
                type: 'line',
                name: '60-69',
                data: [],
                METAOPT_data_report_keys :
                [
                    'AA_GTE60LT70COUNT',
                ]
            },

            {
                type: 'line',
                name: '50-59',
                data: [],
                METAOPT_data_report_keys :
                [
                    'AA_GTE50LT60COUNT',
                ]
            },

            {
                type: 'line',
                name: '<50',
                data: [],
                METAOPT_data_report_keys :
                [
                    'AA_LT50COUNT',
                ]
            },

        ],
    }
}


