import React, { Component } from 'react'


import Search from './patient-search'

class Physicians extends Component {

	render(){
		return (
			<div className='card'>
				<div className='card-header'>
					<div className='row align-items-center'>
						<div className='col'>
							<span className='h4'>Physicians</span>
						</div>
					</div>
				</div>
				<div className='card-body'>
					<div>
						<div className='row'>
							<div className='col'>
								<Search />
							</div>
						</div>
						<div className='row'>
							<div className='col'>
								<ul className='list-group'>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
	
}

export default Physicians
