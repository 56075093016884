export const globalData = {
  test: '1',
  lastTextElement: null,
  eventChart: null,
  loadedChartsData: {},
  loadedEventsData: {},
  eventTop: {},
  allEventsList: {},
  dateOriginTime: 0,
  artifactsList: {},
  artifacts: [],
  artifactPressed: null,
  visibleEvents: [],
  additionalLines: {},
  zoomInterval: { start: 0, end: 300000 },
  initialRanges: {},
  eventTopCustom: null,
  chartRanges: {},
  sectorPolygons: {},
  signalIcon: {},
  drawArtifactFunction: {},
  drawEventFunction: {},
  snapshotsList: [],
  historyPosition: -1,
  chartOffsetTop: 170,
  rowSizeSum: 3,
  gridLines: {},
  maxValuesLines: {},
  minValuesLines: {},
  pulseTags: undefined,
  pulseTagPolygons: [],
  eventsTyped: {},
  token: undefined,
  allEventTypesList: [],
  chartColors: {},
  eventColors: {
    OximetryCycling: '#624c4e60',
    CandidateEvent: '#9e77de8c',
    CentralApnea: '#0000bF80',
    ObstructiveApnea: '#bf000080',
    RERA: '#00bf0080',
    Hypopnea: '#f765af80',
    MixedApnea: '#a9a6a380',
    CheyneStokes: '#14b3c980',
  },
  eventsUpdated: false,
  eventsSaved: false,
  zoomBandArtifacts: {},
  firstChart: null,
  lastDragOutsideTime: 0,
};
