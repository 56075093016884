//=================================================
// goes through dd tree and finds broken nodes
//=================================================

( function() {
    var ns          = window.b$l        = window.b$l        || {};

    var fapp        = ns.fapp           = ns.fapp           || {};
    var fm          = fapp.methods      = fapp.methods      || {};
    var ccc         = window.console.log;

    fm.checkDockDef = checkDockDef;
    return;
    //00000000000000000000000000000000000000000000000000000000000000000000000







    //=================================================
    // //\\ goes through dd tree and finds broken nodes
    //=================================================
    function checkDockDef( ddDef )
    {
        //var propsIn = { table:true, columns:true, stack:true };
        //var propsStop = { text:true, image:true };

        fm.deepGo( ddDef.content );

        function doArr( arr ) {
            var stop = false;
            arr.forEach( (cell,ix) => {
                if( stop ) return;
                stop = deepGo( cell );
                if( stop ) {
                    ccc( 'bad el at ix=' + ix + ' arr=', arr );
                }
            });
            return stop;
        }
        function callBack( unit ) {
           ccc( 'bad dd el found: el=', unit );
        }

        function deepGo( unit )
        {
            var stop;
            if( Array.isArray( unit ) ) {
                stop = doArr( unit );
            } else if( typeof unit === 'object' ) {
                if( unit.table ) {
                    unit.table.body.forEach( row => {
                        stop = doArr( row );
                    });
                } else if( unit.columns ) {
                    stop = doArr( unit.columns );
                } else if( unit.stack ) {
                    stop = doArr( unit.stack );
                } else if( typeof unit.text==='undefined' &&
                           typeof unit.image==='undefined'
                ) {
                    stop = true;
                }
            }
            if( stop ) {
                callBack( unit );
                return true;
            }
        }
    }
    //=================================================
    // \\// goes through dd tree and finds broken nodes
    //=================================================



}) ();
