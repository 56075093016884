import React, {Component} from 'react'
import $ from 'jquery'
import Alert from './alert'
import PWStrength from './password-strength'
import terms from '../assets/terms.pdf'

class Signup extends Component{

	constructor(props){
		super(props)
		this.state = {
			errorMessage: null,
			alertType: 'alert-success',
			password: ''
		}
	}

	submitHandler(event){
		event.preventDefault()
		var elements = event.target.elements
		var password = elements.password.value
		var confirm = elements.confirmPassword.value
		var tos = $('#terms').is(':checked')
		var initAlertMessage = this.initAlertMessage.bind(this)
		var failure = this.errorHandler.bind(this)
		var createUserSuccess = this.createUserSuccess.bind(this)
		if (password.length < 10)
		if (password !== confirm){
			initAlertMessage('passwords do not match.', 'alert-danger')
			return
		}
		if (tos !== true ){
			initAlertMessage('must agree to terms of service.', 'alert-danger')
			return
		}
		var data = {
			email: elements.email.value,
			password: elements.password.value,
			firstName: elements.firstName.value,
			lastName: elements.lastName.value,
		}
		$.post('/users',data).done(function(){
			createUserSuccess(this)
		}).fail(failure)

	}

	createUserSuccess(res){
		var initAlertMessage = this.initAlertMessage.bind(this)
		initAlertMessage('user registered!', 'alert-success')
		// TODO: rediect to login page
	}

	errorHandler(res){
		var body = res.responseJSON
		var error = body.error
		this.setState({
			alertMessage: error.message,
			alertType: 'alert-danger'
		})
	}

	initAlertMessage(message, type){
		this.setState({
			alertMessage: message,
			alertType: type
		})
	}

	removeAlertHandler(){
		this.setState({
			alertMessage: null
		})
	}

	updatePasswordState(e){
		this.setState({
			password: e.target.value
		})
		
	}

	render(){
		var removeHandler = this.removeAlertHandler.bind(this)
		var onSubmit = this.submitHandler.bind(this)
		const {password} = this.state
		return (
			<div id="sign-up">
				<h1 className='display-4 mt-4 mb-4'>Sign Up</h1>
				<form onSubmit={onSubmit} className='text-left'>
					<div className='form-row'>
						<div className="form-group col">
							<label>First Name</label>
							<input className="form-control" name="firstName"/>
						</div>
						<div className="form-group col">
							<label>Last Name</label>
							<input className="form-control" name="lastName"/>
						</div>
					</div>
					<div className="form-group">
						<label>Email address</label>
						<input type="email" className="form-control" name="email" aria-describedby="emailHelp"/>
					</div>
					<div className="form-group">
						<label>Password</label>
						<input type="password" 
						className="form-control" 
						name="password" 
						onChange={this.updatePasswordState.bind(this)} />
					</div>
					<PWStrength password={password}/>
					<div className="form-group">
						<label>Confirm Password</label>
						<input type="password" className="form-control" name="confirmPassword"/>
					</div>
					<div className="form-check mb-3">
						<input type="checkbox" className="form-check-input" id="terms"/>
						<label className="form-check-label">I agree to the</label> <a download href={terms}> Terms of Service</a>
					</div>
					<button type="submit" className="btn btn-primary">Sign Up</button>
				</form>
				<Alert message={this.state.alertMessage} type={this.state.alertType } onExit={removeHandler}/>
			</div>
		)
	}
}

export default Signup