
export default function ( HRM, pstyle ) {
    return  {
        xAxis: {
            categories: [],
        },
        series:
        [
            {
                type: 'line',
                name: 'Autonomic Arousal Index',
                data: [],
                METAOPT_data_report_keys :
                [
                    'AA_INDEX',
                ]
            },
            {
                type: 'line',
                name: 'Pulse Min',
                data: [],
                METAOPT_data_report_keys :
                [
                    'PR_MIN',
                ]
            },
            {
                type: 'line',
                name: 'Pulse Mean',
                data: [],
                METAOPT_data_report_keys :
                [
                    'PR_MEAN',
                ]
            },
            {
                type: 'columnrange',
                name: 'Pulse Max',
                data: [],
                METAOPT_data_report_keys :
                [
                    'PR_MAX',
                ],
            },
            {
                type: 'columnrange',
                name: 'Tachycardia (%)',
                data: [],
                METAOPT_summate_arithmetically : true,
                METAOPT_double_point : true,
                METAOPT_round_to_digits : 0,
                METAOPT_data_report_keys :
                [
                    'PR_GTE90LT100PCT',
                    'PR_GTE100PCT',
                ],
            },
            {
                type: 'columnrange',
                name: 'Bradycardia (%)',
                data: [],
                METAOPT_round_to_digits : 0,
                METAOPT_data_report_keys :
                [
                    'PR_LT50PCT',
                ],
            },
        ],
    }
}




