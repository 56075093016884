
export default function ( HRM, PAGE_WIDTH, PAGE_HEIGHT, availableFonts, availableBorderStyles ) {

return {

    HRM : HRM,

    //:LETTER PDF FORMAT
    PAGE_WIDTH : PAGE_WIDTH * HRM,
    PAGE_HEIGHT : PAGE_HEIGHT * HRM,
    PDFMAKE_PAGE_SIZE : 'LETTER',
    PDFMAKE_PAGE_ORIENTATION : 'portrait',
    cardAspectRatio : PAGE_HEIGHT / PAGE_WIDTH * 0.4,

    PAGE_MARGINS :
    [
        20,
        5, //possibly gives space for page header even no such exist //80,
        15,
        10, //30  //gives space for footer
    ],
    PAGE_HEADER_HEIGHT : 100,
    CARD_MARGINS : [10,10,10,10],

    colorPolicy :
    {
        main : "#073e61",
        waterMark : "#f0f0f0",
    },

    availableFonts :
    [
        'Arial',
        'ArialNova'
    ],

    availableBorderStyles :
    [
        //make sure to implement them in PDF too
        //https://developer.mozilla.org/en-US/docs/Web/CSS/border-style
        'none',
        'solid',
    ],

}};


