
var ns	        = window.b$l;
var fapp        = ns.fapp;
var fmethods    = ns.sn('methods', fapp);
var fconf       = ns.fconf;
var currtpl     = ns.sn('currtpl', fconf);
var aModel      = ns.sn('aModel', fapp);
var pgModels    = ns.sn('pgModels', aModel, []);
var ccc         = console.log; ccc && ( ccc = console.log );

fmethods.doc__dataModel   = doc__dataModel;




///does pages and their card-models
function doc__dataModel()
{
    ///creates imagesDirectory model
    //ccc( 'doc__dataModel() starts ... ' );
    var dt = currtpl.docbody;
    aModel.dataURLs = fmethods.repo2imagesDirectory({ repo:dt.repo.imgRepo  });
    if( !Object.keys( aModel.dataURLs ).length ) {
        ccc( '*** no images repo from ajax for ' + currtpl.doccaption );
    }

    ///creates or refers pages-model placeholder
    dt.pages.population.forEach( (dtpage, pageIx) => {
        pgModels[ pageIx ] =
        pgModels[ pageIx ]
        ||
        {
            pageIx  : pageIx,
            pageTemplate : dtpage, //todo synch name
            pmcards : [],
        };
    });
    //creates card-models array
    fmethods.pagesCards__dataModel();
}

