import React, { useEffect, useState } from 'react';
import { Button, Dropdown } from 'antd';

const compare = (prevProps, nextProps) => {
  if (!prevProps.props.visibleEvents?.length) return false;
  if (
    prevProps.props.visibleEvents?.length !==
    nextProps.props.visibleEvents?.length
  )
    return false;
  return true;
};

export const CustomDropdown = React.memo(({ props }) => {
  const { eventVisibleSelectorClickHandle, eventVisibleItems, visibleEvents } =
    props;
  const [visibleEventTypesSelectorOpen, setVisibleEventTypesSelectorOpen] =
    useState(false);

  useEffect(() => {
    const coords = document
      .getElementById('event_selector_button')
      .getBoundingClientRect();
    const listElement = document.getElementById('event_selector_list');
    listElement.style.top = coords.bottom + 'px';
    listElement.style.left = coords.left - 80 + 'px';
  }, []);

  return (
    // <Dropdown
    //   open={visibleEventTypesSelectorOpen}
    //   menu={{ items: eventVisibleItems, onClick: eventVisibleSelectorClickHandle }}
    //   onOpenChange={(e) => {
    //     if (visibleEventTypesSelectorOpen) setVisibleEventTypesSelectorOpen(false);
    //   }}
    // >
    <Button
      onMouseOver={() => {
        setVisibleEventTypesSelectorOpen(true);
      }}
      onMouseEnter={() => {
        const listElement = document.getElementById('event_selector_list');
        listElement.classList.add('show');
      }}
      onMouseLeave={() => {
        const listElement = document.getElementById('event_selector_list');
        listElement.classList.remove('show');
      }}
      id="event_selector_button"
    >
      {visibleEvents?.length} types
    </Button>

    // </Dropdown>
  );
}, compare);
