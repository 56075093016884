import React from "react";
import { ButtonGroup } from "react-bootstrap";

const ToolBar = (props) => {
    return (
        <div className="row nopadding">
            <div className="col-sm pl-2 pr-0 d-flex align-items-center">
                <ButtonGroup className='d-inline-flex p-2'>
                    <button className={'btn btn-sm border'}
                        title='Save Scoring'>
                        <i className="fa-regular fa-save"></i>
                    </button>
                    <button className={'btn btn-sm border'}
                        title='Save Scoring and Run Report'>
                        <i className="fa-regular fa-file-pdf"></i>
                    </button>
                </ButtonGroup>
                <ButtonGroup className='d-inline-flex p-2'>
                    <button className={'btn btn-sm border'}
                        title='Undo'>
                        <i className="fa-regular fa-undo"></i>
                    </button>
                    <button className={'btn btn-sm border'}
                        title='Redo'>
                        <i className="fa-regular fa-redo"></i>
                    </button>
                </ButtonGroup>
            </div>
            <div className="col-sm px-0 d-flex align-items-center">
                <span style={{ fontSize: 12 }}>Other Signals:</span>
                <ButtonGroup className='d-inline-flex p-2'>
                    <button className={'btn btn-sm'}
                        style={{ color: 'red' }}
                        title='Abdomen'>
                        <i className="fa-regular fa-wave-pulse"></i>
                    </button>
                    <button className={'btn btn-sm'}
                        style={{ color: 'green' }}
                        title='Airflow - Transformed (Type 2)'>
                        <i className="fa-regular fa-wave-pulse"></i>
                    </button>
                    <button className={'btn btn-sm'}
                        style={{ color: 'black' }}
                        title='Chest - Transformed (Type 2)'>
                        <i className="fa-regular fa-wave-pulse"></i>
                    </button>
                    <button className={'btn btn-sm'}
                        style={{ color: 'blue' }}
                        title='Abdomen - Transformed (Type 2)'>
                        <i className="fa-regular fa-wave-pulse"></i>
                    </button>
                    <button className={'btn btn-sm'}
                        style={{ color: 'purple' }}
                        title='Chest'>
                        <i className="fa-regular fa-wave-pulse"></i>
                    </button>
                    <button className={'btn btn-sm'}
                        style={{ color: 'purple' }}
                        title='Airflow'>
                        <i className="fa-regular fa-wave-pulse"></i>
                    </button>
                    <button className={'btn btn-sm'}
                        style={{ color: 'purple' }}
                        title='Snore'>
                        <i className="fa-regular fa-wave-pulse"></i>
                    </button>
                </ButtonGroup>
            </div>
            <div className="col-sm px-0 d-flex align-items-center">
                <span style={{ fontSize: 12, padding: 5 }}>Go To:</span>
                <div className='input-group'>
                    <ButtonGroup>
                        <input type='number' id='epoch' name='epoch' placeholder='epoch #' style={{ width: '5em' }}></input>
                        <button className='btn btn-sm input-group-append border'
                            style={{ color: 'purple' }}
                            title='Go'>
                            Go
                        </button>
                    </ButtonGroup>
                </div>
            </div>
            <div className="col-sm px-0 d-flex align-items-center">
                <span style={{ fontSize: 12, padding: 5 }}>Search:</span>
                <div className="dropdown">
                    <button className="btn btn-sm border dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Desaturation
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <button className="dropdown-item" href="#">Desaturation</button>
                        <button className="dropdown-item" href="#">Oximetry Cycling</button>
                        <button className="dropdown-item" href="#">Oximetry Reciprocation</button>
                        <button className="dropdown-item" href="#">Recovery</button>
                    </div>
                </div>
            </div>
            <div className="col-sm d-flex align-items-center">
                <span style={{ fontSize: 12, padding: 5 }}>110 Events</span>
                <ButtonGroup className='d-inline-flex p-2'>
                    <button className={'btn btn-sm border'}
                        title='Find First Event'>
                        <i className="fa-regular fa-angles-left"></i>
                    </button>
                    <button className={'btn btn-sm border'}
                        title='Find Previous Event'>
                        <i className="fa-regular fa-angle-left"></i>
                    </button>
                    <button className={'btn btn-sm border'}
                        title='Find Next Event'>
                        <i className="fa-regular fa-angle-right"></i>
                    </button>
                    <button className={'btn btn-sm border'}
                        title='Find Last Event'>
                        <i className="fa-regular fa-angles-right"></i>
                    </button>
                </ButtonGroup>
            </div>
            <div className="col-sm d-flex align-items-center">
                <ButtonGroup className='d-inline-flex p-2'>
                    <button className={'btn btn-sm border'}
                        title='Add Artifact Section'>
                        <span style={{ fontSize: 10, padding: 5 }}>Add_Artifact</span>
                    </button>
                    <button className={'btn btn-sm border'}
                        title='Add Score Tag'>
                        <span style={{ fontSize: 10, padding: 5 }}>Add_Event</span>
                    </button>
                </ButtonGroup>
            </div>
            <div className="col-sm d-flex align-items-center">
                <ButtonGroup className='d-inline-flex p-2'>
                    {props.children}
                </ButtonGroup>
            </div>
        </div>
    )
}

export default ToolBar