
export default function ( HRM, pstyle ) {
    //debugger
    return  {
        xAxis: {
            categories: [], //dates
        },
        series:
        [
            {
                type: 'line',
                name: 'Cycling Severity',
                marker:{
                    radius: 6
                },
                lineWidth: 4,
                data: [], //severity
                METAOPT_data_report_keys :
                [
                    'CYCLINGSEVERITY',
                ]
            },
        ],
    }
}




