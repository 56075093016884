//SpO2 Events (# Events In Ranges)                                                  


export default function ( HRM, pstyle ) {
    return  {
        xAxis: {
            categories: [],
        },
        series:
        [
            {
                type: 'line',
                name: '>=90',
                data: [],
                METAOPT_data_report_keys :
                [
                    'SPO2_GTE90LTE100COUNT',
                ]
            },

            {
                type: 'line',
                name: '80-89',
                data: [],
                METAOPT_data_report_keys :
                [
                    'SPO2_GTE80LT90COUNT',
                ]
            },


            {
                type: 'line',
                name: '70-79',
                data: [],
                METAOPT_data_report_keys :
                [
                    'SPO2_GTE70LT80COUNT',
                ]
            },

            {
                type: 'line',
                name: '60-69',
                data: [],
                METAOPT_data_report_keys :
                [
                    'SPO2_GTE60LT70COUNT',
                ]
            },

            {
                type: 'line',
                name: '50-59',
                data: [],
                METAOPT_data_report_keys :
                [
                    'SPO2_GTE50LT60COUNT',
                ]
            },

            {
                type: 'line',
                name: '<50',
                data: [],
                METAOPT_data_report_keys :
                [
                    'SPO2_LT50COUNT',
                ]
            },
        ],
    }
}


