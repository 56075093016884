
//PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP
// //\\ plugin support library
//PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP

//keep care about this rule:
//this actually must be first among window.b$l modules application module to import:
//it loads ns/b$l library as well:
import './conf.js';

import './bsl/core/d8d/d8d-device.js';
import './bsl/core/d8d/d8d-framework.js';
import './bsl/core/d8d/decorator.js';
import './bsl/core/d8d/decorator.css.js';
import './bsl/core/bsl-throttle.js'

import './table-composer/create-global-css.js';
import './table-composer/init-table-d8d.js';
import './table-composer/rebuild-fluid-table.js';
import './table-composer/rebuild-cells.js';
import './pdfbuilder/core/card-table-pdf.js';

import './templates/default-doc.gen.js';

import './reditor/core/init-cards-d8d.js';
import './reditor/core/init-right-sidebar-repo-d8d.js';
import './reditor/core/applib.js';
import './reditor/core/input-overlay.js';
import './reditor/core/applib-upload-file.js';
import './reditor/core/applib-ajax';
import './reditor/core/applib-img2repo.js';
import './reditor/core/dev--get-reports.js';

import './reditor/css-sheets/dashb.css.js';
import './reditor/css-sheets/decorator-handle.css.js';
import './reditor/css-sheets/generic.css.js';
import "./reditor/css-sheets/card-chart-legend.css";
import "./reditor/css-sheets/card-table-html";


import "./reditor/dom-components/plugin-internals.js";
import "./reditor/dom-components/dashb-model.js";
import "./reditor/dom-components/dashb-css.js";
import "./reditor/dom-components/pages.js";

import TopBarControlPanel from "./reditor/components/TopBar-ControlPanel.js";
import LeftSideBarControlPanel from "./reditor/components/LeftSideBar-ControlPanel.js";
import RightSideBarControlPanel from "./reditor/components/RightSideBar-ControlPanel.js";

//---------------------------------------------------------------------
//imports optional plugins
//---------------------------------------------------------------------
import './pdfbuilder/api-entry.js'

//PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP
// \\// plugin support library
//PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP





//==================================================
//imports react
//==================================================
import React from "react";

//==================================================
//sets namespaces for module
//==================================================
var ns	        = window.b$l;
var methods     = ns.sn('methods');
var fconf       = ns.fconf;
var fapp        = ns.sn('fapp' );
var fheap       = ns.sn('fheap', fapp);
var fmethods    = ns.sn('methods', fapp);
var aModel      = ns.sn('aModel', fapp);
var ccc         = console.log; ccc && ( ccc = console.log );

var firstGUIMountDone = false;

fmethods.runs_DashboardIsMount_tasks = runs_DashboardIsMount_tasks;









///==================================================
/// Dashboard main screen
///==================================================
class Dashboard extends React.Component {

    /*
    constructor( props ) {
        super( props );
        //ccc( '###### constructs Dashboard' );
    }
    */
    componentDidMount() {
        fmethods.runs_DashboardIsMount_tasks();
    }
    componentDidUpdate() {
        fmethods.docModel_8_pagesCardsInterns();
    }

    ///renders dashboard
    render() {
        return (
            <div className={"plugin-superroot"}>
                <div className={"plugin-root user-role-"+fconf.ROLE.toLowerCase()}>
                    <TopBarControlPanel />
                    <LeftSideBarControlPanel />
                    <RightSideBarControlPanel />
                    <div className="download-doc-spinner-parent">
                        <div className="download-doc-spinner">
                            <i title="loading document"
                               className="fa fa-spinner fa-pulse fa-3x fa-fw"></i><br/><br/>
                            communicating with server
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Dashboard;




//===============================================
// //\\ runs Dashboard-mount tasks
//===============================================
function runs_DashboardIsMount_tasks()
{
    if( fconf.ENABLE_REP_EDITOR ) {
        //ccc( '### ..Dashb..IsMount_tasks starts in GUI ..' );
        methods.pdf_perAppOneTimeSetup();
        fmethods.doc__dataModel(); //start here ... todo
        fmethods.dash8doc__internals();
        if( !firstGUIMountDone ) {
            //inits dashboard model
            aModel.dividorRightGap_frac = 0;
            fheap.editedPageScale = 1;

            fapp.appver_css();
            //ccc( 'setting resize bouncer ... ' );
            window.addEventListener( 'resize',
                () => {

                    ////this is a patch ... needs a clean scenario
                    ////problem is that when user relogged, the GUI stuff is still there
                    ////and messes up with dumb-pdf-download
                    if( !fconf.ENABLE_REP_EDITOR ) return;

                    fmethods.resizesPlugin_bounced(
                        !'arg_', !'doCallNow', !'doCancel', !!'do_bounce'
                    );
                }
            );

            //doing this because at time of ajax, alarm color
            //cannot be added due non-ready GUI
            //todm ... should be somewhere in CSS-subroutine and not a stand-alone call
            fmethods.update_unsavedFlag_in_CSS();

            firstGUIMountDone = true;
        }

        fmethods.resizesPlugin_bounced();
        methods.pdf__recreate_preview8downl_buttons();
        var pdfButPlaces =
        {
            controlPanel_csspath : '.app-control-panel',
            pdfPreviewContainer_csspath : '.plugin-root',
        }
        methods.pdf__places_iframe_preview_downl_2_html_placeholders( pdfButPlaces );

    } else {
        ccc( '### ..Dashb..IsMount_tasks starts with no GUI ..' );
        methods.pdf_perAppOneTimeSetup();
        fmethods.docModel_8_pagesCardsInterns();
        methods.provides_multNightButton_to_pdf();
        ns.methods.pdfmake8pdfdoc__lifecycle({ pdfTask:'download' });
    }
    //===============================================
    // \\// setus up PDF builder
    //===============================================
}
//===============================================
// \\// runs Dashboard-mount tasks
//===============================================



