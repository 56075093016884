import "./card-table-api.js";
import "./card-dom.js";

//=======================================================================
// //\\ Highcharts
//=======================================================================
// https://www.highcharts.com/docs/
//...we still feed the app from baby-sitter React-HC ...
//...we don't need to do this ... will replace it if needed ...
import Highcharts from 'highcharts'
//--------------------------------------------------------
// //\\ additional stuff for to-pdf-converter
//--------------------------------------------------------
//...let's keep them ... perhaps for nice buger menu converter on charts ...
//import HighchartsReact from 'highcharts-react-official'
import HC_more from 'highcharts/highcharts-more' //module
import HC_exporting from 'highcharts/modules/exporting'
import HC_exporting2 from 'highcharts/modules/offline-exporting'
HC_more(Highcharts) //init module
HC_exporting(Highcharts);
HC_exporting2(Highcharts);
//--------------------------------------------------------
// \\// additional stuff for to-pdf-converter
// \\// Highcharts
//=======================================================================



( function() {
    var ns          = window.b$l        = window.b$l        || {};
    var fapp        = ns.sn('fapp' ); 
    var fconf       = ns.fconf;
    var fmethods    = ns.sn('methods',fapp);
    var aModel      = ns.sn('aModel', fapp);
    var methods     = ns.methods        = ns.methods        || {};
    var ccc         = console.log; ccc && ( ccc = console.log );

    fmethods.card__internals    = card__internals;
    fmethods.cnt__chart   = cnt__chart;
    fmethods.cnt__picture = cnt__picture;
    return;





    function card__internals({ pmcard })
    {
        var dataGr = pmcard.dataGr;
        if( dataGr.isChart ) {
            cnt__chart({ pmcard, });
        } else if( dataGr.isTable ) {
            methods.calls_tableAPI({ pmcard });
            pmcard.dtable.dat8tpl_2_model8inlcss( !fconf.ENABLE_REP_EDITOR );
        } else if( dataGr.isPicture ) {
            cnt__picture({ pmcard });
        }
    }


    function cnt__chart({ pmcard })
    {
        //adjusts chart aspect ratio to screen
        var ch = pmcard.cdataOptions.chart;
        ch.height = ch.width * pmcard.tplHight_px/pmcard.tplWidth_px;
        if( !fconf.ENABLE_REP_EDITOR ) return;

        try{
            //ccc( pmcard.pageIx + ' ' + pmcard.cardIx + ' co=', pmcard.cdataOptions );
            Highcharts.chart( pmcard.cardDom$$.hChartParent$(), pmcard.cdataOptions );
        } catch (chartErr ) {
            ccc( chartErr );
        }
    }


    function cnt__picture({ pmcard })
    {
        //adjusts chart aspect ratio to screen
        var dtcard = pmcard.dtcard;
        //todm ... later to distort the image:
        //dtcard.height = dtcard.width * pmcard.tplHight_px/pmcard.tplWidth_px;
        if( !fconf.ENABLE_REP_EDITOR ) return;
        /*
        //recall tree:
        //editorPage$
            pageCard$ = d$$.pcard$ = dataId + ' dsg-id--' + dsg_id + " page-card " + cardOverlayId
                .chart-super-parent cardid-"+cix
                        d$$.hChartParent$ = .chart-parent cardid-"+cix
        */
        var img$;
        pmcard.cardDom$$.hChartParent$
            .html( '' )  //todo inconsistent ... wrong place?
            .css( 'width', '100%' ) //todo inconsistent ... wrong place
            .css( 'height', '100%' ) //todo inconsistent ... wrong place
            .ch( pmcard.cardDom$$.img$ = img$ = ns.$$.img()
                  .src( aModel.dataURLs[ dtcard.tpl.imsrc ] )
                  .addClass( 'card-picture' )
                  .css( 'width', '100%' )
                  .css( 'height', '100%' )
                  .css( 'cursor', 'pointer' )
            )
            ;
        if( fconf.ROLE !== 'CONSTRUCTOR' ) return;

        img$    
          .e( 'click', () => {
            //if( dtcard.dsg_id === 'pages-header' ) return; //todo

            fmethods.managesUploadOverlay({

                //.adds/replaces image to/in dataURLs
                modelObject : aModel.dataURLs,
                model_prop0ix : dtcard.tpl.imsrc,
                dom$ : img$,
                verticalPosition : dtcard.dsg_id === 'pages-header' ?
                {
                    loc : 'bottom',
                    offset : '-2px',
                } :
                null,

                //==================================================
                // //\\ picture-card addition
                //==================================================
                taskCompletionCallback : ( newImageId ) => {

                    var repo = fconf.currtpl.docbody.repo;
                    //does update the images storage in doc-template
                    //the same way as for added image or replaced image
                    repo.imgRepo = fmethods.imgDirectory2repo( aModel.dataURLs );
                    if( dtcard.tpl.imsrc !== newImageId ) {
                        var formerDataId = dtcard.tpl.imsrc;
                        ////image is added in media-lib ...
                        ////todm ... here is a lot of bookkeeping ... do simple
                        var newdg = ns.fmethods.clonePictureTpls(
                                    repo.dataGroups, newImageId );
                        fmethods.updateRightSideBar();

                        //replaces former dtcard
                        var pos = dtcard.pos;
                        var newDSg = newdg.subGrs[ dtcard.dsg_ix ];
                        ns.cleanpaste( dtcard, newDSg );
                        dtcard.pos = pos;

                        if( dtcard.dsg_id === 'pages-header' ) {
                            ////replaces former dtcard twins everywere in other headers
                            fmethods.oneheaderTpl_2_allPages_model8dom({
                                pmcard, skipOwn:true, search_dataId : formerDataId,
                            });
                        }
                    } else {
                        ////image is replaced in media-lib ... we must update it
                        ////everywhere where this dataId is met for consistancy
                        fmethods.replacedDataURL_to_all_pages_card({ pmcard, });
                    }
                },
                //==================================================
                // \\// picture-card addition
                //==================================================



                //==================================================
                // //\\ picture-card deletion
                //      from an entire doc
                //==================================================
                taskDeletionCallback : () => {
                    //ccc( 'does deletion' );
                    var repo = fconf.currtpl.docbody.repo;
                    var dataId = dtcard.dataId;

                    //------------------------------------------------
                    // //\\ purge what we can
                    //------------------------------------------------

                    //models ...
                    aModel.pgModels.forEach( ( pageModel,pageIx ) => {
                        fmethods.removeCardsDom8models( pageIx );
                    });
                    //purges pictures model list
                    delete aModel.dataURLs[ dataId ];

                    //templates ...
                    //doc pages tpls
                    fmethods.delete_dataId_fromDocTpl( dataId );

                    ///purges pictures repo
                    repo.imgRepo = fmethods.imgDirectory2repo( aModel.dataURLs );

                    /*
                    ccc( dataId + ' deletion: repo.imgRepo=' +
                         repo.imgRepo.substring(0, 40 ) +
                         '\naModel.dataURLs=', Object.keys( aModel.dataURLs ) )
                    */
                    var dgs = repo.dataGroups;
                    var indexOfDGroup = dgs.map( dg =>
                        ( dg.dataId === dataId && 1 ) ).indexOf( 1 );
                    if( indexOfDGroup > -1 ) {
                        dgs.splice( indexOfDGroup, 1 );
                        ns.fmethods.reindexSplicedDataGroups( dgs );
                        var wwdb = fconf.currtpl.docbody;
                        if( wwdb.contrPanelChosen_tplDatGrIx >= dgs.length ) {
                            wwdb.contrPanelChosen_tplDatGrIx = dgs.length-1;
                        }
                        //ccc( 'reindexed dgs=', dgs );
                    }
                    //------------------------------------------------
                    // \\// purge what we can
                    //------------------------------------------------
                    fmethods.pages__datMod_8_internals();
                    fmethods.updateRightSideBar();
                },
                //==================================================
                // \\// picture-card deletion
                //==================================================
            }); //fmethods.managesUploadOverlay({
          } //anonymous in event
        ); //event
    } //function cnt__picture

}) ();
