import React, { Component } from 'react'
import moment from 'moment'
import $ from 'jquery'


class SubscriptionItem extends Component {

	constructor(props) {
		super(props)
		this.state = {
			activeCard: {},
			defaultValue: 'none'
		}
	}
	componentDidMount() {
		this.setActiveCard(this.props.data.default_source)
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.cards.length > 0) {
			if (!this.state.activeCard.id) {
				this.setActiveCard(this.props.data.default_source)
			}
		}
		if (this.state.activeCard !== prevState.activeCard) {
			this.setDefaultValue()
		}
		if (this.props.subscriptions[this.props.index] !== prevProps.subscriptions[this.props.index]) {
			this.setActiveCard(this.props.subscriptions[this.props.index].default_source)
		}
	}

	setDefaultValue() {
		const newValue = this.state.activeCard.brand + ' ' + this.state.activeCard.last4
		this.setState({
			defaultValue: newValue
		})

	}

	setActiveCard(data) {
		if (!data) {
			data = this.props.customer.default_source
		}
		if (this.state.activeCard.id === data) return
		for (let index = 0; index < this.props.cards.length; index++) {
			const card = this.props.cards[index];
			if (card.id === data) {
				const newValue = card.brand + ' ' + card.last4
				if (this.state.defaultValue !== newValue) {
					this.setState({
						defaultValue: newValue
					})
				}
			}
		}
	}

	handleChange(event) {
		var value = event.target.value
		var splitValue = value.split(' ')
		var cardType = ''
		if (splitValue.length > 2) {
			for (var index = 0; index < splitValue.length - 1; index++) {
				const string = splitValue[index];
				if (index > 0) {
					cardType = cardType + ' ' + string
				} else {
					cardType = string
				}

			}
		} else {
			cardType = splitValue[0]
		}
		var last4 = splitValue[splitValue.length - 1]
		for (var i = 0; i < this.props.cards.length; i++) {
			if (this.props.cards[i].brand === cardType && this.props.cards[i].last4 === last4) {
				this.setDefaultSource(this.props.cards[i])
			}
		}
	}

	setDefaultSource(card) {
		var url = '/organizations/' + this.props.organization.id + '/subscriptions/' + this.props.data.id
		var success = this.handleActiveCard.bind(this)
		this.setState({
			activeCard: card
		})
		$.ajax({
			url: url,
			method: 'PUT',
			data: {
				default_source: card.id
			}
		}).done(success)
	}

	handleActiveCard(data) {
		this.setActiveCard(data.default_source)
	}

	renderCardOptions(card) {
		var value = card.brand + ' ' + card.last4
		return (
			<option value={value} id={card.id} key={card.last4}>{card.brand} {card.last4}</option>
		)
	}

	render() {
		var data = this.props.data
		var plan = data.plan

		var date = new Date(data.current_period_end * 1000);
		var renewalDate = moment(date).format('MMMM DD, YYYY')
		var amount = parseFloat(plan.amount / 100).toFixed(2)
		var cardOptions = this.props.cards.map(this.renderCardOptions.bind(this))

		if (!data.default_source) {
			cardOptions.push(<option disabled={true} hidden={true} value='none' key='none'>None</option>)
		}

		return (
			<li className='list-group-item'>
				<div className='row'>
					<div className='col'>
						<div className='row'>
							<span className='font-weight-bold'>Product: </span> {plan.nickname}
						</div>
						<div className='row'>
							<span className='font-weight-bold'>Total Price: </span> ${amount*data.quantity} 
						</div>
					</div>
					<div className='col'>
						<div className='row'>
							<span className='font-weight-bold'>Period: </span>{plan.interval}
						</div>
						<div className='row'>
							<span className='font-weight-bold'>Status: </span>{data.status}
						</div>
					</div>
					<div className='col'>
						<div className='row'>
							<span className='font-weight-bold'>Renewal Date: </span>{renewalDate}
						</div>
						<div className='row'>
						<span className='font-weight-bold'>Quantity: </span>{data.quantity}
						</div>
					</div>
					<div className='col'>
						<span className='font-weight-bold'>Payment Method: </span>
						<div style={{ padding: 0 }}>
							<select className="custom-select mr-sm-2"
								onChange={this.handleChange.bind(this)}
								name='exp_month'
								value={this.state.defaultValue}>
								{cardOptions}
							</select>
						</div>
					</div>
				</div>
			</li>
		)
	}

}

export default SubscriptionItem
