// Minimal ajax wrapper, ajy.
// Origin is taken from /var/www/html/sand/web/tools/pdf/prj/saving-pdf-selfsufficient-prooftest.zip


( function () {
    //attaches framework to top namespace
    window.b$l = window.b$l || {};
    window.b$l.ajax = createAjaxFramework();
    return;



    

    function createAjaxFramework()
    {
        var ajy = {};
        if( typeof XMLHttpRequest === 'undefined' ) throw new Error('No AJAX');
        var xml = ajy.xml = new XMLHttpRequest();

        ajy.send = function ( args )
        {
            //===========================================================================
            var {  responseType, ajaxURL, method, adata, formencode,
                   onsuccess, onerror } = args; //API
            //===========================================================================
            if( responseType ) {
                //for example: responseType = 'arraybuffer';
                xml.responseType = responseType;
            }
            method = ( method && method.toUpperCase() ) || 'GET';
            xml.onreadystatechange = function () 
            {
                if ( xml.readyState === 4 && xml.status === 200 ) {
                    //to get returned data, use: ajy.xml.responseText
                    onsuccess( ajy );
                } else {
                    onerror && onerror( 'Ajax problems with URL ' + ajaxURL );
                }
            };
            try{ 
                xml.open( method, ajaxURL, true );
                if( formencode ) {
                    xml.setRequestHeader(
                       'Content-type', 'application/x-www-form-urlencoded');
                }
                xml.send( adata || null );
            } catch ( err ) {
                onerror && onerror( 'AJAX open or send problems. Error='+err );
                //Give up: xml.send(null);
            }
        };    
        return ajy;
    }

})();
