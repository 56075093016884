import { InfluxDB } from '@influxdata/influxdb-client'
import React, { Component } from 'react'
import CanvasJSReact from '../assets/canvasjs.stock.react';
var CanvasJSStockChart = CanvasJSReact.CanvasJSStockChart;



class SubscriptionItem extends Component {

	constructor(props) {
		super(props)
		this.state = {
			datetimeFormat: "HH:mm:ss DD-MMMM-YY",
			data_spo2: [],
			data_pulse: [],
			data_movement: []
		}
	}

	componentDidMount() {
		this.setChartData()
	}

	async setChartData() {

		//const exQuery = 'from(bucket:"Signals") |> range(start: 1561226400, stop: 1561312800) |> filter(fn: (r) => r._measurement == "temperature")'
		const querySignalType1 = `from(bucket:"Signals")
|> range(start: 1561226400, stop: 1561312800)
|> filter(fn: (r) => r["_measurement"] == "signals" and r["signalType"] == "1")
|> keep(columns: ["_time", "_value"])
|> group()
|> sort(columns:["_time"])`

		const querySignalType2 = `from(bucket:"Signals")
|> range(start: 1561226400, stop: 1561312800)
|> filter(fn: (r) => r["_measurement"] == "signals" and r["signalType"] == "2")
|> keep(columns: ["_time", "_value"])
|> group()
|> sort(columns:["_time"])`

		const querySignalType4 = `from(bucket:"Signals")
|> range(start: 1561226400, stop: 1561312800)
|> filter(fn: (r) => r["_measurement"] == "signals" and r["signalType"] == "4")
|> keep(columns: ["_time", "signalType", "_value"])
|> group()
|> sort(columns:["_time"])`
		const spo2 = await this.getData(querySignalType1)
		const pulse = await this.getData(querySignalType4)
		const movement = await this.getData(querySignalType2)
		this.setState({
			data_spo2: spo2,
			data_pulse: pulse,
			data_movement: movement
		})
	}

	async getData(query) {
		let url = "https://eastus-1.azure.cloud2.influxdata.com"
		let token = "EQ5Bv0SLlUTfi4seAdZX8v3GU_egyWsEF11OfyodAVWwTYjr4YoG2W4T_c_0jnRJEntI5DYxuCTqKipK3uHEpQ=="
		let org = "8fb4c72154ad8c56"

		const queryApi = new InfluxDB({ url, token }).getQueryApi(org)
		var arr = [];
		await queryApi.collectRows(query).then(data => {
			data.forEach(x => {
				let val = { x: new Date(x._time), y: x._value };
				arr.push(val);
			});
		})

		return arr;
	}

	RenderSpO2Chart() {

	}

	render() {
		var minX = this.state.data_spo2.length > 0 ? new Date(this.state.data_spo2[0]['x']) : 0
		var maxX = this.state.data_spo2.length > 0 ? new Date(this.state.data_spo2[this.state.data_spo2.length - 1]['x']) : 0
		const options = {
			charts: [
				//SpO2 chart
				{
					height: 250,
					zoomEnabled: true,
					zoomType: "x",
					axisY: {
						title: "SpO2",
						minimum: 70,
						maximum: 100,
						labelTextAlign: "left"
					},
					axisX: {
						labelFormatter: function () {
							return " ";
						}
					},
					data: [{
						type: "splineArea",
						color: "#2dc42d",
						dataPoints: [
							{ x: minX, y: 97.4 },
							{ x: maxX, y: 97.4 },
						]
					}, {
						type: "splineArea",
						color: "#264653",
						xValueFormatString: this.state.datetimeFormat,
						toolTipContent: "SpO2: {y} <br> {x}, ",
						fillOpacity: 0.8,
						dataPoints: this.state.data_spo2
					}]
				},
				//Pulse Chart 
				{
					height: 250,
					zoomEnabled: true,
					zoomType: 'x',
					axisY: {
						title: "Pulse",
						minimum: 50,
						maximum: 90,
						labelTextAlign: "left"
					},
					axisX: {
						labelFormatter: function () {
							return " ";
						}
					},
					data: [
						{
							type: "line",
							color: "#e9c46a",
							xValueFormatString: this.state.datetimeFormat,
							toolTipContent: "Pulse: {y} <br> {x}, ",
							dataPoints: this.state.data_pulse
						}]
				}, {
					//Movement Chart
					height: 100,
					zoomEnabled: true,
					zoomType: 'x',
					axisY: {
						title: "Movement",
						labelTextAlign: "left"
					},
					axisX: {
						labelFormatter: function () {
							return " ";
						}
					},
					data: [
						{
							type: "line",
							color: "#e76f51",
							xValueFormatString: this.state.datetimeFormat,
							toolTipContent: "Movements: {y} <br> {x}, ",
							dataPoints: this.state.data_movement
						}]
				}],
			rangeSelector: {
				enabled: false
			},
			navigator: {
				verticalAlign: "bottom",
				height: 50,
				axisY: {
					title: "SpO2",
					minimum: 85,
					maximum: 100,
					labelTextAlign: "left"
				},
				slider: {
					minimum: minX,
					maximum: maxX,
				},
				data: [{
					type: "spline",
					color: "#264653",
					fillOpacity: 0.8,
					dataPoints: this.state.data_spo2
				}]
			}
		};

		const containerProps = {
			margin: "auto",
			height: 665,
			dynamicUpdate: false
		};
		return (
			<div>
				<CanvasJSStockChart
					options={options}
					containerProps={containerProps}
					onRef={ref => this.stockChart = ref}
				/>

			</div>
		);
	}

}

export default SubscriptionItem
