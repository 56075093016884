
import './doc-model.js';
import './page-dom.js';
import "./page-css.js";
import './card-model.js';
import "./card-content.js";
import "./card-all.js";
import "./card-css.js";



//=======================================================================
// //\\ Highcharts
//=======================================================================
// https://www.highcharts.com/docs/
//...we still feed the app from baby-sitter React-HC ...
//...we don't need to do this ... will replace it if needed ...
import Highcharts from 'highcharts'
//--------------------------------------------------------
// //\\ additional stuff for to-pdf-converter
//--------------------------------------------------------
//...let's keep them ... perhaps for nice buger menu converter on charts ...
//import HighchartsReact from 'highcharts-react-official'
import HC_more from 'highcharts/highcharts-more' //module
import HC_exporting from 'highcharts/modules/exporting'
import HC_exporting2 from 'highcharts/modules/offline-exporting'
HC_more(Highcharts) //init module
HC_exporting(Highcharts);
HC_exporting2(Highcharts);
//--------------------------------------------------------
// \\// additional stuff for to-pdf-converter
// \\// Highcharts
//=======================================================================






( function() {
    var ns          = window.b$l        = window.b$l        || {};
    var fapp        = ns.sn('fapp' );
    var fmethods    = ns.sn('methods', fapp);
    var ccc         = console.log; ccc && ( ccc = console.log );
    fmethods.page__internals = page__internals;
    return;




    function page__internals( pgModel )
    {
        fmethods.dom__pageFrame8cardFrames({ pgModel });
        //.todm find out why this cannot live after following line for charts
        fmethods.cards__internals( pgModel );
        fmethods.inlcss__page8cardsFrames( pgModel )
    }



}) ();
