
var ns          = window.b$l;
var sn          = ns.sn;
var fapp        = sn('fapp');
//var fconf       = ns.sn('fconf', fapp);
var nheap       = ns.nheap          = ns.nheap          || {};
var ddCont      = nheap.ddCont      = nheap.ddCont      || [];
//var imagesRack  = nheap.imagesRack  = nheap.imagesRack  || {};
var ccc         = console.log; ccc && ( ccc = console.log );

fapp.build_cardTable_inPDF = build_cardTable_inPDF;





//********************************************
// fluid-positioned table style
//********************************************
function build_cardTable_inPDF({ pmcard })
{
    var tableWrapper = [];

    //-------------------------------
    // //\\ parent and shortcuts
    //-------------------------------
    var dtable      = pmcard.dtable;

    var ts          = dtable.style8data_tpl;

    //var metr        = dtable.metrics;
    var tblBox =
    {
        width : ts.width,
        left : ts.left + pmcard.tplLeft_px,
        top : ts.top + pmcard.tplTop_px,
    }


    /*
    var fconf       = sn('fconf', fapp);
    var currtpl     = ns.sn('currtpl', fconf);
    ccc(
        'currtpl.docbody.pstyle.PAGE_WIDTH=' + currtpl.docbody.pstyle.PAGE_WIDTH + ' ' +
         'twidth='+ ts.width + ' pmcard.leftP='+ pmcard.tplLeft_px+ 'ts.left + pmcard.tplLeft_px='+tblBox.left)
    */
    var land0port = ts.orientationLandscape ? 'landscape' : 'portrait';
    var colFr = ts.columnFractions[ land0port ];
    var widths = [];
    var firstCellWidth;
    for( var cix = 0; cix < colFr.length; cix++ ) {
        var wd =    Math.max( 2,
                        ( colFr[ cix ] * ts.width
                            -
                            ts.paddings[0] -
                            ts.paddings[2]
                        )
                    );
        //ccc( cix+ ' pdf major cell width=' + ( colFr[ cix ] * ts.width ).toFixed(1) );
        //ccc( cix+ ' pdf cell inner width=' + ( wd ).toFixed(1) );

        if( cix === 0 ) {
            ////todm ... patch ... used for <hr> element
            firstCellWidth = wd;
        }
        widths.push( wd );
    }
    //================================================
    // //\\ protects against non equally long rows
    //      puts nice message
    //================================================
    var h8r = ns.paste( [], pmcard.dtable.scapified_head8rows );
    var rowsLength = 0;
    h8r.forEach( row => {
        if( row.length > rowsLength ) {
            rowsLength = row.length;
        }
    });
    ///fills extra cells in short rows with "no data" string
    h8r.forEach( row => {
        if( row.length < rowsLength ) {
            for( var rix = row.length; rix<rowsLength; rix++ ) {
                row[rix] = 'no data';
            }
        }
    });
    //================================================
    // \\// protects against non equally long rows
    //================================================


    ///clones scapified_head8rows into tbody and makes sure all cells are of
    ///type string
    var tbody = pmcard.dtable.scapified_head8rows.map( (row,rix) => row.map( (cell,cix) => {
        var cellCssModel = dtable.cssModel__rows[ rix ][ cix ];
        var cellval = dtable.dataModel__rows[ rix ][ cix ];

        ///todm ... patch ... by trick of matching content ...
        if( typeof cellval === 'string' && cellval.match( /<hr>/ ) ) {
            var docContent = {
                canvas:
                [
                    ///horizontal line element
                    { type : 'line',
                      lineColor : cellCssModel.backgroundColor,
                      x1 :  0,
                      y1 : 0,
                      x2 : firstCellWidth,
                      y2 : 0,
                      lineWidth : cellCssModel[ 'padding-top' ] + cellCssModel[ 'padding-bottom' ],
                    },
                ]
            }

        } else {
            ///this fragment builds cell of table's body
            docContent = 
            {
                text        : cellval,
                font        : cellCssModel.fontFamily,
                bold        : cellCssModel.fontWeight,
                alignment   : cellCssModel.textAlign,
                italics     : cellCssModel.fontStyle,

                color       : cellCssModel.fontColor,


                //todm ... perhaps subtract borders and not to scale paddings and fonts?
                //lineHeight  : cellCssModel.lineHeight/1.15,
                lineHeight  : cellCssModel.lineHeight/1.18,
                //todo patch ... unclear why ...
                //perhaps font aspect ratio is different for PDF and HTML? ...
                //fontSize    : cellCssModel.fontSize/1.35, //todo patch ... unclear why
                //fontSize    : cellCssModel.fontSize/1.1,    //todo patch ... unclear why
                fontSize    : cellCssModel.fontSize,

            };
        }
        return docContent; 
    }) );

    //================================================
    // //\\ sets borders
    //================================================
    tbody.forEach( (row,rix) => {
        row.forEach( (cell,cix) => {
            var cellCssModel = dtable.cssModel__rows[ rix ][ cix ];
            cell.border = cellCssModel.borderStyle === 'none' ?
                [false, false, false, false] :
                [true,true,true,true];
        });
    });
    //================================================
    // \\// sets borders
    //================================================


    // ... just for tables html/pdf width synch test
    /*
    var tableMatch =
    {
        absolutePosition : {
          x: tblBox.left,
          y: tblBox.top,
        },
        canvas:
        [
            { type: 'line',
              lineColor: '#ff0000',
              x1: 0,                y1: 0,
              x2: tblBox.width,     y2: 0,
              lineWidth: 2
            },
        ],
    }
    */

    var assembledTable =
    {
        
        absolutePosition : {
          x: tblBox.left,
          y: tblBox.top,
        },
        //possibly no effect width : tblBox.width,
        //margin: [ 0, tblBox.top, 0, 0 ],
        //margin: [ tblBox.left, tblBox.top, 0, 0 ],
        margin: [ 0, 0, 0, 0 ],

        layout: {
            hLineWidth: () => 0.01,
            vLineWidth: () => 0.01,
            hLineColor: ts.editableCss.borderColor,
            vLineColor: ts.editableCss.borderColor,
            //.combined with heights
            //todm ... perhaps subtract borders and not to scale paddings
            paddingTop:     function(i, node) { return ts.paddings[1]*0.8  || 0; },
            paddingBottom:  function(i, node) { return ts.paddings[3]*0.8  || 0; },
			paddingLeft:    function(i, node) { return ts.paddings[0]*0.8  || 0; }, //todm patch
            paddingRight:   function(i, node) { return ts.paddings[2]*0.8  || 0; }
        },
        //fontSize : ts.editableCss.fontSize
        //           * 0.8 //todo patch
        //,
        color: ts.editableCss.fontColor,
        //lineHeight : ts.editableCss.lineHeight*0.9,
        table: {
            //possibly no effect width : tblBox.width,
            headerRows: 1,
            dontBreakRows: false,
            widths : widths,
            body : tbody,
            //.combined with paddingTop and paddingBottom
            //.this thing really increases the height if needed
            //heights: tableRack.cellHeight || 11,
        }
    }
    tableWrapper = tableWrapper.concat( assembledTable );
    //if( pmcard.pageIx === 0 && pmcard.dtcard.dsg_id === 'pages-header' )
    //    c cc( 'assembledTable=', assembledTable );

    //tableWrapper = tableWrapper.concat( tableMatch );

    ///transfers tableWrapper into pdfmake document-content
    tableWrapper.forEach( el => { ddCont.push( el ); });
}


