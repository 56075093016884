
export default function ( HRM, pstyle ) {
    return  {
        xAxis: {
            categories: [], //dates
        },
        series:
        [
            {
                type: 'line',
                name: 'Total RDI',
                data: [],
                METAOPT_data_report_keys :
                [
                    'RDI4_OVERALL',
                ]
            },
            {
                type: 'line',
                name: 'RERA 2-4% Index',
                data: [],
                METAOPT_data_report_keys :
                [
                    'RDI4_RERA2TO4',
                ]
            },
            {
                type: 'line',
                name: 'Desat >4% Index',
                data: [],
                METAOPT_data_report_keys :
                [
                    'RDI4_DESAT4',
                ]
            },
            {
                type: 'columnrange',
                name: 'High Baseline - Low Baseline',
                data: [],
                METAOPT_round_to_digits : 1,
                METAOPT_double_point : true,
                METAOPT_data_report_keys :
                [
                    'SPO2_BASELINE_HIGH',
                    'SPO2_BASELINE_LOW',
                ],
            },
            {
                type: 'line',
                name: 'Cycling Time (Airway Instability)',
                data: [],
                METAOPT_data_report_keys :
                [
                    'SPO2_INST_PCT',
                ]
            },
            {
                type: 'line',
                name: 'Cycling Severity',
                data: [],
                METAOPT_data_report_keys :
                [
                    'CYCLINGSEVERITY',
                ]
            },
        ],
    }
}




