( function() {
    var ns          = window.b$l;
    var sn          = ns.sn;    
    var fapp        = sn('fapp' ); 
    var fmethods    = ns.sn('methods', fapp);
    var ccc         = console.log; ccc && ( ccc = console.log );

    var ESC = '#';
    var ESC_SELF = ESC + '@';
    var RECORD_SEPARATOR = ';';
    var ID_SEPARATOR = ':';

    var REPLACE_ESC = new RegExp( ESC, 'g' );
    var REVERT_ESC = new RegExp( ESC_SELF, 'g' );

    fmethods.img2repo = img2repo;
    fmethods.repo2imagesDirectory = repo2imagesDirectory;
    fmethods.imgDirectory2repo = imgDirectory2repo;

    //============================================================================
    // //\\ test
    //============================================================================
    /*
    var dataURL =
                "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUA" +
                "AAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO" +
                "9TXL0Y4OHwAAAABJRU5ErkJggg==";
    var repo = fmethods.img2repo({
                imageid : 'first-folder/image.png',
                dataURL,
                repo : '',
    });
    repo = fmethods.img2repo({
                imageid : 'second-folder/image.png',
                dataURL,
                repo,
    });
    var dir = repo2imagesDirectory({ repo });
    ccc( 'repo=', repo );
    ccc( 'dir=', dir );
    */
    //============================================================================
    // \\// test
    //============================================================================
    return;






    function img2repo({ imageid, dataURL, repo })
    {
        var escaped_id      = imageid.replace( REPLACE_ESC, ESC_SELF );
        var escaped_dataURL = dataURL.replace( REPLACE_ESC, ESC_SELF );
        repo = ( repo && ( repo + ESC + RECORD_SEPARATOR ) ) || '';
        repo += escaped_id + ESC + ID_SEPARATOR + escaped_dataURL;
        return repo;
    }

    function repo2imagesDirectory({
        repo //optional, if falsy, then creates empty directory = {}
    }) {
        var records = repo && repo.split( ESC + RECORD_SEPARATOR );
        records = records || [];
//ccc( repo, 'must be records=', records );
        var imagesDirectory = {};
        records.forEach( rec => {
            var keyPair = rec.split( ESC + ID_SEPARATOR );
            //ccc( 'rec=', rec , 'keyPair=', keyPair )
            imagesDirectory[
                keyPair[ 0 ].replace( REVERT_ESC, ESC )
            ] =
                keyPair[ 1 ].replace( REVERT_ESC, ESC )
            ;
        })
        return imagesDirectory;
    }


    function imgDirectory2repo( imgDirectory )
    {
        return Object.keys( imgDirectory ).reduce(
            (acc,key) => {
                var dataURL = imgDirectory[ key ];
                return ns.fmethods.img2repo({
                            imageid : key,
                            dataURL,
                            repo : acc,
                });
            },
            '', //empty string as accumulator
        );
    }

}) ();

