import React, { Component } from "react";
import { Link } from "react-router-dom";
import OrganizationDropdown from "./organization-dropdown";

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let nameAbr = "nn";
    if (this.props.user.firstName && this.props.user.lastName) {
      nameAbr = (this.props.user.firstName.charAt(0) + this.props.user.lastName.charAt(0)).toUpperCase();
    }
    var orgId = this.props.organization.id || 0;
    var orgLink = "/organizations/" + orgId;
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-white sticky-top border-bottom">
        <div className="navbar-nav mr-auto">
          <OrganizationDropdown user={this.props.user} organization={this.props.organization} organizations={this.props.organizations} handleOrganization={this.props.handleOrganization} />
        </div>
        <div className="col text-right align-items-center">
          <div className="d-flex flex-row-reverse">
            <div className="dropleft" id="userDropdown">
              <b className="btn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {nameAbr}
              </b>
              <div className="dropdown-menu" aria-labelledby="userDropdown">
                <Link className="dropdown-item" to="/profile" type="button" id="profile">
                  <i className="mr-3 far fa-user-circle" />
                  Profile
                </Link>
                <Link className="dropdown-item" to={orgLink} type="button" id="profile">
                  <i className="mr-3 far fa-building" />
                  Organization
                </Link>
                <Link className="dropdown-item" to="/subscriptions" type="button" id="profile">
                  <i className="mr-3 far fa-tag" />
                  Subscriptions
                </Link>
                <Link className="dropdown-item" to="/members" type="button" id="profile">
                  <i className="mr-3 far fa-users" />
                  Members
                </Link>
                <Link className="dropdown-item" to="/connections" type="button" id="profile">
                  <i className="mr-3 far fa-exchange-alt" />
                  Connections
                </Link>
                <Link className="dropdown-item" to="/video-tours" type="button" id="profile">
                  <i className="mr-3 fab fa-youtube" style={{ color: "red" }} />
                  All Video Tours
                </Link>
                <button className="dropdown-item" to="/logout" type="button" id="logout" onClick={this.props.logout}>
                  <i className="mr-3 fad fa-sign-out-alt" />
                  Logout
                </button>
              </div>
            </div>
            <Link className="btn far fa-cog" type="button" to="/settings"></Link>
            <button className="btn far fa-bell"></button>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
