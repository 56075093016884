import React, {Component} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons'

class Paginator extends Component{
	
	constructor(props){
		super(props)
		this.state = {
			data: props.data,
			page: 1
		}
		this.limitData = this.limitData.bind(this)
		this.renderPageItems = this.renderPageItems.bind(this)
		this.renderPageItem = this.renderPageItem.bind(this)
		this.handlePrevious = this.handlePrevious.bind(this)
		this.handleNext = this.handleNext.bind(this)
		this.setPage = this.setPage.bind(this)
	}

	componentDidUpdate(prevProps,prevState){
		var propsDataChanged = prevProps.data !== this.props.data
		var pageChanged = prevState.page !== this.state.page
		if (propsDataChanged || pageChanged){
			this.limitData(this.props.data)
		}
	}

	limitData(){
		var data = this.props.data
		var limit = this.props.limit
		var offset = (this.state.page - 1) * limit
		var end = offset + limit
		var limitedData = data.slice(offset,end)
		this.props.render(limitedData)
	}

	getNearbyPagesInRange(range){
		var count = this.props.data.length
		var currentPage = this.state.page
		var maxPages = Math.ceil(count / this.props.limit)
		var minMargin = 0
		var maxMargin = 0
		var min = 1
		var max = 0
		if (currentPage - range <= 0) {
			min = 1
			minMargin = currentPage - range - min
		} else min = currentPage - range
		if (currentPage + range >= maxPages){
			max = maxPages
			maxMargin = currentPage + range - maxPages
		} else max = currentPage + range
		if (minMargin < 0 && maxPages - max > 0) {
			max = max - minMargin >= maxPages ? maxPages : max - minMargin
		}
		if (maxMargin > 0 && min > 1){
			min = maxMargin - min < 1 ? 1 : min - maxMargin
		}
		return {
			min: min,
			max: max
		}
	}

	renderPageItems(){
		var range = this.getNearbyPagesInRange(2)
		var pages = []
		var currentPage = this.state.page
		for (var i = range.min; i <= range.max; i++){
			if (i > 0){
				var isActive = i === currentPage
				pages.push(this.renderPageItem(i,isActive))
			}
		}
		return pages
	}

	renderPageItem(num,isActive){
		var active = isActive ? 'active' : ''
		return(
			<li key={num} className={'page-item ' + active} >
				<button id={num} className='page-link' onClick={this.setPage}>{num}</button>
			</li>
		)
	}

	handlePrevious(){
		var page = this.state.page - 1
		if (page < 1) page = 1
		this.setState({
			page: page
		})
	}

	handleNext(){
		var count = this.props.data.length
		var maxPages = Math.ceil(count / this.props.limit)
		var page = this.state.page + 1
		if (page > maxPages) page = maxPages
		this.setState({
			page: page
		})
	}

	setPage(event){
		var page = parseInt(event.target.id)
		this.setState({
			page: page
		})
	}

	render(){
		if (this.props.data.length === 0) return (<ul></ul>)
		return(
			<ul className='pagination justify-content-center'>
			    <li className='page-item' onClick={this.handlePrevious}>
					<button className='page-link'>
						<FontAwesomeIcon icon={faAngleDoubleLeft} />
					</button>
			    </li>
			    {this.renderPageItems()}
			    <li className='page-item'>
				    <button className='page-link' onClick={this.handleNext}>
						<FontAwesomeIcon icon={faAngleDoubleRight} />
					</button>
				</li>
			</ul>
		)
	}
}

export default Paginator