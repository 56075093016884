
import './doc-model.js';
import './page-dom.js';
import './card-model.js';
import "./card-content.js";
import "./card-css.js";



//=======================================================================
// //\\ Highcharts
//=======================================================================
// https://www.highcharts.com/docs/
//...we still feed the app from baby-sitter React-HC ...
//...we don't need to do this ... will replace it if needed ...
import Highcharts from 'highcharts'
//--------------------------------------------------------
// //\\ additional stuff for to-pdf-converter
//--------------------------------------------------------
//...let's keep them ... perhaps for nice buger menu converter on charts ...
//import HighchartsReact from 'highcharts-react-official'
import HC_more from 'highcharts/highcharts-more' //module
import HC_exporting from 'highcharts/modules/exporting'
import HC_exporting2 from 'highcharts/modules/offline-exporting'
HC_more(Highcharts) //init module
HC_exporting(Highcharts);
HC_exporting2(Highcharts);
//--------------------------------------------------------
// \\// additional stuff for to-pdf-converter
// \\// Highcharts
//=======================================================================






( function() {
    var ns          = window.b$l        = window.b$l        || {};
    var fconf       = ns.fconf;
    var currtpl     = ns.sn('currtpl', fconf);
    var fapp        = ns.sn('fapp' );
    var fmethods    = ns.sn('methods', fapp);
    var fheap       = ns.fheap;
    var ccc         = console.log; ccc && ( ccc = console.log );
    fmethods.inlcss__pageWrappers = inlcss__pageWrappers;
    fmethods.inlcss__page8cardsFrames = inlcss__page8cardsFrames;
    return;


    function inlcss__page8cardsFrames( pm )
    {
        fmethods.dashModel_of_pagePosModel( pm );
        inlcss__pageWrappers( pm );
        pm.pmcards.forEach( pmcard => {
            fmethods.inlcss__doResizeCardFrames({ pmcard });
        });
    }


    //================================================
    // //\\ page pos and css
    //================================================
    function inlcss__pageWrappers( pm  )
    {
        var xstyle = currtpl.docbody.pstyle;
        var pscale = fheap.editedPageScale;
        pm.dom$$.editorPage$
            .css( 'width',  ( pscale * xstyle.PAGE_WIDTH ).toFixed(2) + 'px' )
            .css( 'height', ( pscale * xstyle.PAGE_HEIGHT ).toFixed(2) + 'px' )
            .css( 'left',   ( pscale * pm.pageLeft ).toFixed(2) + 'px' )
            .css( 'top',    ( pscale * pm.pageTop ).toFixed(2) + 'px' )
            ;
    }
    //================================================
    // \\// page pos and css
    //================================================

}) ();
