import React, { Component } from 'react'
import User from './user'
import Settings from './settings'

class Profile extends Component {

    render(){
        return(
            <div>
                <User location='/users'
                user={this.props.user}></User>
                {<Settings organization={this.props.organization}></Settings>}
                
            </div>
        )
    }
}

export default Profile