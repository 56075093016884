
var dataOptions =
{
    title: {
        text: 'Cycling Time'
    },
    navigation: {
        buttonOptions: {
            enabled: false
        }
    },
    chart: {
        width: 500,
        height: 500,
        plotBackgroundColor: {
            linearGradient: { x1: 0, x2: 0, y1: 1, y2: 0 },
            stops: [
                [0, '#0e8204'], //green
                [.1, '#0e8204'], //green
                [.2, '#fff700'], //yellow
                [.5, '#fc5000'], //red
                [1, '#ba0202'], //dark red
            ]
        },
    },
    yAxis: {
        title: {
            text: '% of VRT'
        },
        min: 0,
        max: 40,
        tickInterval: 10,
        gridLineColor: 'black',
        
    },
    plotOptions: {
        line: {
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: '15px'
                }
            }
        },
    },
    credits: {
        enabled: false
    },
};

export default dataOptions;


