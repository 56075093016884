
var ns          = window.b$l;
var sn          = ns.sn;
var fapp        = sn('fapp');
var ccc         = console.log; ccc && ( ccc = console.log );

fapp.appver_css__chart8card8table = appver_css__chart8card8table;




function appver_css__chart8card8table()
{
    return appver_css__chart8card() + appver_css__legendTable();

    ///=============================================
    /// HTML
    ///=============================================
    function appver_css__chart8card()
    {
        return `
            .chart-super-parent,
            .chart-parent,
            .page-card {
                position : absolute;
                z-index : 10;
            }
            .page-card {
                width : 100%;
            }
        `;
    }

    ///=============================================
    /// HTML
    ///=============================================
    function appver_css__legendTable()
    {
        return `
            /*****************************************/
            /* //|| legend table                     */
            /*****************************************/
            .legend-parent {
                position : absolute;
                z-index : 20;
            }
            /*****************************************/
            /* ||// legend table                     */
            /*****************************************/
        `;
    }
}

