import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash, faSave, faBan } from '@fortawesome/free-solid-svg-icons'

class Edit extends Component {

	renderDestroy(){
		if (this.props.action.destroy){
			return (
				<button className='btn btn-sm btn-outline-danger'
					type='button'
					onClick={ this.props.actions.destroy }>
					<FontAwesomeIcon icon={ faTrash } />
				</button>
			)
		} else {
			return (<div></div>)
		}
		
	}

	render(){
		var actions = this.props.actions
		var locked = this.props.locked
 		var hide = { display: 'none' }
 		var show = { display: 'block' }
		return (
			<div className='edit'>
				<div className='btn-group' 
					role='group' 
					style={ locked ? hide : show }>
					<button className='btn btn-sm btn-outline-success'
						type='submit' 
						onClick={ actions.submit }>
						<FontAwesomeIcon icon={ faSave } />
					</button>
					
					<button className='btn btn-sm btn-outline-danger'
						type='button' 
						onClick={ actions.read }>
						<FontAwesomeIcon icon={ faBan } />
					</button>
				</div>
				<div className='btn-group' 
					role='group'
					style={ locked ? show : hide } >
					<button className='btn btn-sm btn-outline-primary' 
						type='button' 
						onClick={ actions.unlock }>
						<FontAwesomeIcon className='' icon={ faPen } />
					</button>
				</div>
			</div>
		)
	}


}


export default Edit