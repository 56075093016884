import React from "react";
import pageStyle from "../../templates/page-style.conf.js"

var ns          = window.b$l;
var fapp        = ns.sn('fapp' ); 
var fmethods    = ns.sn('methods', fapp);
var ccc         = console.log; ccc && ( ccc = console.log );

fmethods.doDisableDecorationControls = doDisableDecorationControls;

////gives user slice of time to do styles before hiding controls
fmethods.bouncedDecorationControlsDisabler = ns.bouncer(
    doDisableDecorationControls,
    20000, //how much more time to give, ms
);



function doDisableDecorationControls()
{
    if( !ns.fconf.ENABLE_REP_EDITOR ) return; //todo patch if user relogin and GUI is lost
    var dc = document.querySelector( '.content-decoration-controls' );
    if( !dc ) return; //todo ... !dc happens when patient is changed ...
                      //         it's too complex code to trace for the moment ...
                      //         hopefully other coder does not fall in this trap ...
    dc.style.display = 'none';
}

///shortcut-function
function onFontProp({
        that,
        event,
        propName,
        propValue_fun,
    }) {
        var propValue = propValue_fun( event );
        fmethods.bouncedDecorationControlsDisabler();
        if( propValue === null ) {
            ////does noting if propValue is not validated
            propValue = event.target.value;
        } else {
            fmethods.set_userWork_unsavedFlag_in_CSS( !!'changed' );
            that.state.styles_2_tpl8html({
                propName,
                propValue,
            });
        }
        var updatedProps = { [propName] : propValue };
        that.setState( updatedProps );
}







class FontDecorations extends React.Component {

    constructor( props ) {
        super( props );
        var this_state =
        {
            styles_2_tpl8html : props.styles_2_tpl8html || (_=>{}),
        };
        ns.eachprop( ns.fconf.editableCss, ( prop, propkey ) => {
            ////optional-properties to state:
            this_state[ propkey ] = props[ propkey ] || prop;
        });
        this.state = this_state;
        //.for text box to control current decorations
        fmethods.elStyles_2_decorControls___setState = this.setState.bind( this );
    }

    onFontSize = ( event ) => {
        onFontProp({
            that : this,
            event,
            propName : 'fontSize',
            propValue_fun : event => {
                var val = parseInt(event.target.value);
                //.validates
                if( isNaN( val ) ) return null;
                return val;
            },
        })
    }

    onBorderWidth = ( event ) => {
        onFontProp({
            that : this,
            event,
            propName : 'borderWidth',
            propValue_fun : event => {
                var val = parseInt(event.target.value);
                //.validates
                if( isNaN( val ) ) return null;
                return val;
            },
        })
    }

    onFontLineHeight = ( event ) => {
        onFontProp({
            that : this,
            event,
            propName : 'lineHeight',
            propValue_fun : event => {
                var val = parseFloat(event.target.value);
                //.validates
                if( isNaN( val ) ) return null;
                return val;
            },
        })
    }

    onFontWeight = ( event ) => {
        onFontProp({
            that : this,
            event,
            propName : 'fontWeight',
            propValue_fun : event => event.target.checked,
        })
    }

    onFontStyle = ( event ) => {
        onFontProp({
            that : this,
            event,
            propName : 'fontStyle',
            propValue_fun : event => event.target.checked,
        })
    }

    onFontColor = ( event ) => {
        onFontProp({
            that : this,
            event,
            propName : 'fontColor',
            propValue_fun : event => {
                var val = event.target.value;
                ///validates color string
                if(
                    !val.match( /^#(\d|[a-fA-F]){6}\s*$/ )
                    && !val.match( /^transparent$/ )
                ) {
                    return null;
                }
                return val;
            },
        })
    }

    onBackgroundColor = ( event ) => {
        onFontProp({
            that : this,
            event,
            propName : 'backgroundColor',
            propValue_fun : event => {
                var val = event.target.value;
                ///validates color string
                if(
                    !val.match( /^#(\d|[a-fA-F]){6}\s*$/ )
                    && !val.match( /^transparent$/ )
                ) {
                    return null;
                }
                return val;
            },
        })
    }

    onFontFamily = ( event ) => {
        onFontProp({
            that : this,
            event,
            propName : 'fontFamily',
            propValue_fun : event => event.target.value,
        })
    }

    onTextAlign = ( event ) => {
        onFontProp({
            that : this,
            event,
            propName : 'textAlign',
            propValue_fun : event => event.target.value,
        })
    }

    onBorderColor = ( event ) => {
        onFontProp({
            that : this,
            event,
            propName : 'borderColor',
            propValue_fun : event => {
                var val = event.target.value;
                ///validates color string
                if( !val.match( /^#(\d|[a-fA-F]){6}\s*$/ ) ) {
                    return null;
                }
                return val;
            },
        })
    }

    onBorderStyle = ( event ) => {
        onFontProp({
            that : this,
            event,
            propName : 'borderStyle',
            propValue_fun : event => event.target.value,
        })
    }

    render() {
        return (
            <div className="content-decoration-controls" >
                <div className="dcontrol font-family">
                    <select
                            className="font-family-entry"
                            value={this.state.fontFamily}
                            onChange={this.onFontFamily} 
                    >
                    {
                        pageStyle().availableFonts.map( ffamily => {
                            return (
                                <option key={ffamily} name={ffamily} value={ffamily}
                                >{ffamily}
                                </option>
                            );
                        })
                    }
                    </select>
                </div>
                <div className="dcontrol font-size">
                    <input
                            className="font-size-entry"
                            value={this.state.fontSize}
                            onChange={this.onFontSize} 
                    />
                </div>
                <div className="dcontrol text-line-space">
                    line space &nbsp;
                    <input 
                            className="font-size-entry"
                            value={ this.state.lineHeight.toFixed(2) }
                            onChange={this.onFontLineHeight} 
                    />
                </div>
                <div className="dcontrol font-bold">
                    bold <input
                            className="font-bold-entry"
                            checked={this.state.fontWeight}
                            type="checkbox"
                            onChange={this.onFontWeight} 
                    />
                </div>
                <div className="dcontrol font-italic">
                    italic <input
                            className="font-italic-entry"
                            checked={this.state.fontStyle}
                            type="checkbox"
                            onChange={this.onFontStyle} 
                    />
                </div>
                <div className="dcontrol font-color">
                    color &nbsp;
                    <input 
                            className="font-color-entry"
                            value={this.state.fontColor}
                            onChange={this.onFontColor} 
                    />
                </div>
                <div className="dcontrol font-color">
                    bg color &nbsp;
                    <input 
                            className="background-color-entry"
                            value={this.state.backgroundColor}
                            onChange={this.onBackgroundColor} 
                    />
                </div>
                <div className="dcontrol text-align">
                    <select
                        className="text-align-entry"
                        value={this.state.textAlign}
                        onChange={this.onTextAlign} 
                    >
                    {
                        ['left','center','right'].map( align => {
                            return (
                                <option key={align} name={align} value={align}
                                >{align}
                                </option>
                            );
                        })
                    }
                    </select>
                </div>
                <div className="dcontrol border-style">
                    border <select
                            className="border-style-entry"
                            value={this.state.borderStyle}
                            onChange={this.onBorderStyle} 
                    >
                    {
                        pageStyle().availableBorderStyles.map( opt => {
                            return (
                                <option key={opt} name={opt} value={opt}
                                >{opt}
                                </option>
                            );
                        })
                    }
                    </select>
                </div>
                <div className="dcontrol border-width">
                    <input
                            className="border-width-entry"
                            value={this.state.borderWidth}
                            onChange={this.onBorderWidth} 
                    />
                </div>
                <div className="dcontrol border-color">
                    border color &nbsp;
                    <input 
                            className="border-color-entry"
                            value={this.state.borderColor}
                            onChange={this.onBorderColor} 
                    />
                </div>
            </div>
        );
    }
}

export default FontDecorations;

