( function() {
    var ns          = window.b$l;
    var sn          = ns.sn;
    var fapp        = sn('fapp');
    var fmethods    = sn('methods',fapp);
    var ccc         = console.log; ccc && ( ccc = console.log );
    var frameworkCssEstablished = false;
    fmethods.createGlobalCss = createGlobalCss;
    return;







    ///=============================================
    /// established basic non-inline css for dtables
    ///=============================================
    function createGlobalCss({ dtable, })
    {
        //does this css only once per framework
        if( frameworkCssEstablished ) return;
        frameworkCssEstablished = true;

        var globalCss = ns.sn( 'globalCss' );

        globalCss.update( `
            /******************************************/
            /* //|| original table global CSS         */
            /******************************************/
            .dtable {
                /* using this can make clone out of precision synch
                border : 1px solid green;
                */
            }
            .dtable,
            .dtable .d-tr,
            .dtable .td {
                box-sizing : border-box;
            }

            .dtable .d-tr {
                position : static;
                white-space : nowrap;
            }
            /*
            .dtable.absolute-tr .d-tr {
                position : absolute;
            }
            */
            /* this makes table rows possible */
            .dtable .d-tr:after {
                content : "";
                display: block; 
                clear: both;
            }

            .dtable .td {
                position        : relative;
                display         : inline-block;
                vertical-align  : top; /* important */
                white-space     : normal;
            }
            /*
            .dtable.absolute-tr .td {
                position : absolute;
            }
            */

            /******************************************/
            /* ||// original table global CSS         */
            /******************************************/


            /******************************************/
            /* //|| fluid table global CSS            */
            /******************************************/
            .dtable.fluid-table {
                table-layout: fixed;
            }

            .dtable .fluid-cell {
                position : relative;
                /* note: pdfmake apparently uses "content-box": synchronizing with it */
                box-sizing : border-box;
            }
            /******************************************/
            /* ||// fluid table global CSS            */
            /******************************************/



            /******************************************/
            /* //|| cloned table global CSS           */
            /******************************************/
            .divtable {
                position    : absolute;
                box-sizing  : border-box;
                /* border : 1px solid blue; */
            }
            .divheader, .divrow, .divcell {
                position    : absolute;
                box-sizing  : border-box;
            }
            /******************************************/
            /* ||// cloned table global CSS           */
            /******************************************/


            /*========================================*/
            /* //|| drag handles                      */
            /*========================================*/

            .table-handle {
                display : none;
            }
            .table-handles-shown .table-handle {
                display : block;
            }

            .table-handles-toggler,
            .table-handle {
                position    : absolute;
                width       : 16px;
                height      : 16px;
                cursor      : pointer;
                z-index     : 10000;
            }

            /* //\\ table-handles-toggler */
            .table-handles-toggler {
                left : -22px;
                top : -22px;
                opacity : 0.5;
            }
            .table-handles-toggler:hover {
                opacity : 1;
            }
            /* \\// table-handles-toggler */

            .table-handle img {
                position : absolute;
                width : 16px;
                height : 16px;
                opacity : 0.5;
            }
            .table-handle:hover img {
                opacity : 1;
            }

            /* table size */
            .table-handle.size-hanldle {
                left : 0;
                bottom : 0;
            }
            .table-handle.size-hanldle img {
                left : 0;
                bottom : 0;
            }

            /* column size */
            .table-handle.column-handle {
                right : 0px;
                bottom : 0;
            }
            .table-handle.column-handle img {
                width : 5px;
                height : 14px;
                right : 0;
                top : 0;
            }

            /********************************/
            /* //\\ inserters */
            /********************************/
            .table-handle.row-inserter {
                left : -20px;
                top : -7px;
            }
            .table-handle.record-inserter {
                left : -7px;
                top : -18px;
            }
            .table-handle.row-remover {
                left : -20px;
                top : 7px;
            }
            .table-handle.record-remover {
                left : 7px;
                top : -18px;
            }
            /********************************/
            /* \\// inserters */
            /********************************/


            /* moves table up and down */
            .table-handle.move-handle {
                width : 28px;
                height : 7px;
                left : 40px;
                top : 0px;
            }
            .table-handle.move-handle img {
                width : 28px;
                height : 7px;
                left : 0;
                top : 0;
            }

            /* flip-orientation. flips rows to columns. */
            .table-handle.flip-orientation {
                left : 65px;
                top : -20px;
            }
            .table-handle.flip-orientation img {
                left : 0;
                top : 0;
            }
            /*========================================*/
            /* ||// drag handles                      */
            /*========================================*/



            /*========================================*/
            /* //|| highlights editable cell at hover */
            /*========================================*/
            /*
            disabled for now because of
            collides with row-inserter/row-remover;
            .fluid-table td.not-under-edit:hover {
                overflow : hidden;
            }
            */
            .fluid-table td.not-under-edit:hover:after {
                content : ' ';
                position : absolute;
                background-color: ${ns.fconf.CP_OVERLAYS_MASTER_COLOR};

                /* these values only denote the beginning of the cell because of
                   there is no easy way to retrieve the size of the cell
                */
                width : 20px;
                height : 15px;

                top : 0px;
                left : 0px;
                opacity : 0.2;
            }
            /*========================================*/
            /* ||// highlights editable cell at hover */
            /*========================================*/
        `);
    }

}) ();

