import React, { Component } from 'react'
import MultiNightItem from './multi-night-report-list-item'
import $ from 'jquery'
import Paginator from './paginator'

class MultiReports extends Component {

	constructor(props){
		super(props)
		this.state = {
			reports: [],
			multiReports: [],
			filteredReports: [],
			limitedReports: [],
			fromDate: '',
			toDate: '',
			limit: 10,
			offset: 0,
			hidePending: false,
			hideComplete: false,
			hideInvalid: true,
			totalReports: 0,
			currentPage: 1
		}
	}

	componentDidMount(){
		this.getReports()
		this.getMultiReports()
	}

	componentDidUpdate(prevProps,prevState){
		if (prevProps.organization.id !== this.props.organization.id){
			this.getReports()
			this.getMultiReports()
			
		}
		if (this.props.patient && prevProps.patient.id !== this.props.patient.id){
			this.getReports()
			this.getMultiReports()
			
		}
	}

	getReports(){
		var data = {}
		var success = this.handleReports.bind(this)
		if (this.props.patient){
			data.patientId = this.props.patient.id
		}

		if (this.props.organizationId){
			data.patientId = this.props.organization.id
		}

		$.ajax({
			url: '/reports',
			data: data
		}).done(success)
	}

	getMultiReports(){
		var success = this.handleMultiReports.bind(this)
		$.ajax({
			method: 'GET',
			url: '/multi-night-reports',
			data: {
				organizationId: this.props.organization.id,
				patientId: this.props.patient.id
			}
		}).done(success)
	}

	handleMultiReports(data){
		this.setState({ 
			multiReports: data
		})
	}

	handleReports(data){
		this.setState({ 
			reports: data
		})
	}

	renderReports(data){
		this.setState({
			limitedReports: data
		})
	}

	handleRemoveSuccess(){
		this.getMultiReports()
	}

	renderListItem(data,index){
		return <MultiNightItem data={data} key={index} removeHandler={this.handleRemoveSuccess.bind(this)} reports={this.state.reports}></MultiNightItem>
	}

 	render(){
		var reportItems = this.state.multiReports.map(this.renderListItem.bind(this))
		var isPatientView = window.location.hash.includes('patients') ? true : false
		 
 		return (
			<div className='card'>
				<div className='card-header'>
					<span className='h4'>Multi-Night Reports</span>
				</div>
				<div className='card-body'>
					<div>
						<ul className='list-group mt-2 mb-2' >
							<li className='list-group-item lead text-center'>
								<div className='row '>
									<div className='col-6'>
										<span>Nights Included</span>
									</div>
									<div className='col-2'>
										<span>Compilation Date</span>
									</div>
									<div className='col' hidden={isPatientView}>
										<span>Patient</span>
									</div>
									<div className='col-2'>
										<span></span>
									</div>
								</div>
							</li>
							{reportItems}
						</ul>
					</div>
					<nav>
						<Paginator data={this.state.filteredReports}
							limit={this.state.limit} 
							render={this.renderReports}/>
					</nav>
				</div>
			</div>
		)
 	}

}

export default MultiReports
